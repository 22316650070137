import React from "react";
import styles from "./DifferentNumber.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../../store/slices/gameSlice";
import CommonConstant from "../../../../../common/constants/commonConstant";
import GameConstant from "../../../../../common/constants/gameConstant";
const DifferentNumber = () => {
  const dispatch = useDispatch();
  const { betList: betListData } = useSelector(
    (state) => ({
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const betList = betListData || { nums1: [], nums2: [], checked: false };

  const btnChecked = betList?.checked;

  // Functions
  const updateBet = (val = null, listType) => {
    let tempBetList = { ...betList };

    if (val) {
      // Handle nums
      let tempNums = [...tempBetList[listType]];
      let idx = tempNums.indexOf(val);
      if (idx > -1) tempNums.splice(idx, 1);
      else tempNums.push(val);
      tempBetList[listType] = tempNums;
    } else {
      // Handle btn checked
      tempBetList.checked = !tempBetList.checked;
    }

    if (tempBetList.checked || tempBetList["nums1"].length >= 3 || tempBetList["nums2"].length >= 2) dispatch(gameAction.setDrawer({ open: true }));
    else dispatch(gameAction.setDrawer({ open: false }));

    // Update stored bet list
    dispatch(gameAction.setBetList(tempBetList));
  };

  // Components
  const NumberRow = ({ values, itemClassName, listType }) => {
    const targetList = betList?.[listType] || [];
    return (
      <div className={styles.boxNumRow}>
        {values.map((value, index) => (
          <div
            key={`${value}-${index}`}
            className={`${itemClassName} ${styles.numItem} ${targetList.includes(value) && styles.active}`}
            onClick={() => updateBet(value, listType)}
          >
            {value}
          </div>
        ))}
      </div>
    );
  };

  // Render
  return (
    <>
      <div className={styles.betWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 3 different numbers</div>
          <div className={styles.oddsTitle}>Odds: 34.56</div>
        </div>
        <NumberRow values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.DifferentNumber]} itemClassName={styles.numbox1} listType={"nums1"} />
        <div className={styles.remark}>
          *Choose 3 or more numbers. The three numbers in the drawing result are different numbers. If they match the selected number, you will win.
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 3 consecutive numbers</div>
          <div className={styles.oddsTitle}>Odds: 8.64</div>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={`${styles.button} ${btnChecked && styles.active}`} onClick={() => updateBet()}>
            Choose 3 consecutive numbers
          </div>
        </div>
        <div className={styles.remark}>*If the drawn numbers are any three consecutive numbers, you will win.</div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 2 different numbers</div>
          <div className={styles.oddsTitle}>Odds: 6.91</div>
        </div>
        <NumberRow values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.DifferentNumber]} itemClassName={styles.numbox1} listType={"nums2"} />
        <div className={styles.remark}>
          *Choose 2 or more numbers. The two numbers in the drawing result are two different numbers. If they coincide with the selected number, you will win.
        </div>
      </div>
    </>
  );
};

export default DifferentNumber;
