import GameConstant from "../constants/gameConstant";
import ImageConstant from "../constants/imageConstant";
import RouteConstant from "../constants/routeConstant";

const CommonMapper = {
  RouteGame: {
    [RouteConstant.K5d]: GameConstant.Games.K5d,
    [RouteConstant.Wingo]: GameConstant.Games.WinGo,
    [RouteConstant.K3]: GameConstant.Games.K3,
  },
  GameImage: {
    [GameConstant.Games.WinGo]: ImageConstant.winGoGame.winGoHomeLogo,
    [GameConstant.Games.K3]: ImageConstant.k3Game.k3HomeLogo,
    [GameConstant.Games.K5d]: ImageConstant.k5dGame.k5dHomeLogo,
  },
};

export default CommonMapper;
