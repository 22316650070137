import React, { useEffect, useRef, useState } from "react";
import styles from "./CountdownTimer.module.css";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../store/slices/gameSlice";

const CountdownTimer = () => {
  const dispatch = useDispatch();
  const { nextCron, gameTime, gameBlock } = useSelector(
    (state) => ({
      nextCron: state.game.nextCron,
      gameTime: state.game.gameTime,
      gameBlock: state.game.gameBlock,
    }),
    shallowEqual
  );

  const countDownRef = useRef();
  const nextCronRef = useRef();
  const gameBlockRef = useRef();

  const [timeRemaining, setTimeRemaining] = useState(null);

  const updateTimer = () => {
    let { minutes, seconds } = nextCronRef.current || { ...nextCron[gameTime] };

    // Decrement seconds
    seconds--;

    // If seconds go below zero, decrement minutes and reset seconds to 59
    if (seconds < 0) {
      minutes--;
      seconds = 59;
    }

    if (minutes < 0) {
      minutes = gameTime - 1;
    }

    // Split seconds into two parts
    let seconds1 = seconds >= 10 ? parseInt(`${seconds}`.split("")[0]) : 0;
    let seconds2 = seconds % 10;

    setTimeRemaining({ minutes, seconds1, seconds2 });

    nextCronRef.current.minutes = minutes;
    nextCronRef.current.seconds = seconds;

    // Conditional logic for showing/hiding game block
    if (minutes === 0 && seconds1 === 0 && seconds2 <= 5) {
      // Show block
      if (!gameBlockRef.current) {
        gameBlockRef.current = true;
        dispatch(gameAction.setGameBlock(true));
        dispatch(gameAction.setDrawer({ open: false }));
        dispatch(gameAction.clearBetList());
      }
    }
    if (minutes > 0 || (minutes >= 0 && seconds1 >= 1 && seconds2 <= 9)) {
      // Hide block
      if (gameBlockRef.current) {
        gameBlockRef.current = false;
        dispatch(gameAction.setGameBlock(false));
      }
    }
  };

  useEffect(() => {
    if (gameTime && nextCron?.[gameTime]) {
      nextCronRef.current = { ...nextCron[gameTime] };
      let minutes = nextCronRef.current.minutes;
      let seconds = nextCronRef.current.seconds;

      let seconds1 = seconds >= 10 ? parseInt(`${seconds}`.split("")[0]) : 0;
      let seconds2 = seconds % 10;

      setTimeRemaining({ minutes, seconds1, seconds2 });
      clearInterval(countDownRef.current);
      countDownRef.current = setInterval(updateTimer, 1000);
    }
    return () => clearInterval(countDownRef.current);
  }, [gameTime, nextCron]);

  return (
    <div className={styles.countdownTimer}>
      <div className={styles.countdownTimerItem}>{0}</div>
      <div className={styles.countdownTimerItem}>{timeRemaining?.minutes || 0}</div>
      <div className={styles.countdownymbol}>:</div>
      <div className={styles.countdownTimerItem}>{timeRemaining?.seconds1 || 0}</div>
      <div className={styles.countdownTimerItem}>{timeRemaining?.seconds2 || 0}</div>
    </div>
  );
};
export default CountdownTimer;
