import { useEffect, useState } from "react";
import { deleteFromLocalStorage, getLocalStorage, setLocalStorage } from "../../common/helpers/localStorage";

const useMutePopUp = (mutePopUpHandlerName) => {
  const mutePopUpHandler = getLocalStorage(mutePopUpHandlerName);
  const [popUpMuted, setPopUpMuted] = useState(true);

  useEffect(() => {
    if (mutePopUpHandler) {
      setPopUpMuted(true);
    } else {
      deleteFromLocalStorage(mutePopUpHandlerName);
      setPopUpMuted(false);
    }
  }, [mutePopUpHandler]);

  const handleMutePopUp = (isMute) => {
    if (isMute) {
      setLocalStorage(mutePopUpHandlerName, true);
      setPopUpMuted(true);
    } else {
      deleteFromLocalStorage(mutePopUpHandlerName);
      setPopUpMuted(false);
    }
  };

  return { popUpMuted, handleMutePopUp };
};

export default useMutePopUp;
