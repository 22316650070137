import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Slot.module.css";
import ImageConstant from "../../../../common/constants/imageConstant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../../common/constants/gameConstant";
import { gameAction } from "../../../../store/slices/gameSlice";

const Slot = () => {
  const dispatch = useDispatch();
  const { gameInfo, gameTime, gameResult, isNewResult, resultModalOpen } = useSelector(
    (state) => ({
      gameInfo: state.game.gameInfo,
      gameTime: state.game.gameTime,
      gameResult: state.game.gameResult,
      isNewResult: state.game.isNewResult,
      resultModalOpen: state.game.resultModalOpen,
    }),
    shallowEqual
  );

  const roundHistory = gameInfo[GameConstant.RecordType.RoundHistory];
  const prevResult = roundHistory.items?.[0]?.result;

  const SlotItems = useMemo(() => Array.from({ length: 30 }, (_, idx) => idx % 10), []);
  const BetPositions = ["A", "B", "C", "D", "E"];

  const slotRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  const [spin, setSpin] = useState(false);
  const [boxes, setBoxes] = useState(Array.from({ length: 5 }).map(() => SlotItems));
  const [resultTotal, setResultTotal] = useState(false);

  useEffect(() => {
    if (isNewResult && resultModalOpen) {
      setSpin(true);
    }

    let resultToShow = null;

    if (isNewResult) {
      resultToShow = gameResult.split("");
    } else {
      let resultOnScreen = String(boxes.map((b) => b[0]).join(""));
      if (resultOnScreen !== prevResult && roundHistory.pageConfig.currentPage === 1) {
        resultToShow = prevResult?.split("");
      }
    }

    if (resultToShow) {
      setResultTotal(resultToShow.reduce((acc, curr) => Number(acc) + Number(curr), 0));
      let newBoxes = slotRefs.map((_, idx) => [-1, resultToShow?.[idx] || -1, ...SlotItems]);

      if (isNewResult) {
        setTimeout(() => {
          setBoxes(newBoxes);
          setSpin(false);
        }, 2500);
      } else {
        setBoxes(newBoxes);
      }
    }
  }, [gameResult, prevResult]);

  return (
    <div className={styles.betBox}>
      <div className={styles.slotRoot}>
        <span className={`${styles.sideBox} ${styles.sideBoxLeft}`} />
        <span className={`${styles.arrow} ${styles.arrowLeft}`}>
          <img src={ImageConstant.k5dGame.arrowLeft} alt="arrowLeft" />
        </span>

        <div className={styles.slotContainer}>
          <div className={styles.slotLabels}>
            {BetPositions.map((l, idx) => (
              <span key={idx}>{l}</span>
            ))}
          </div>
          <div className={styles.slotWrapper}>
            {slotRefs.map((ref, idx_1) => (
              <div key={idx_1} className={`${styles.boxContainer}`} ref={ref}>
                <div className={`${styles.boxes} ${spin ? styles.spinning : ""}`} style={{ animationDelay: `${idx_1 * 0.18}s` }}>
                  {boxes[idx_1].map((box, idx_2) => (
                    <span key={idx_2} className={`${styles.box}`}>
                      {box}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <span className={`${styles.arrow} ${styles.arrowRight}`}>
          <img src={ImageConstant.k5dGame.arrowRight} alt="arrowRight" />
        </span>
        <span className={`${styles.sideBox} ${styles.sideBoxRight}`} />
      </div>
      <span className={styles.total}>Total = {resultTotal}</span>
    </div>
  );
};

export default Slot;
