import React from "react";
import CountdownCircleAnimation from "../../common/countDownCircleAnimation/CountDownCircleAnimation";
import { shallowEqual, useSelector } from "react-redux";

const GameBlocker = ({ children }) => {
  const { gameBlock, globalBlock } = useSelector(
    (state) => ({
      gameBlock: state.game.gameBlock,
      globalBlock: state.game.globalBlock,
    }),
    shallowEqual
  );

  return (
    <div className="relative game-blocker">
      {gameBlock && !globalBlock.show && (
        <>
          <div className={`absolute bg-[#151747cc] opacity-40 !z-[999999] w-full h-full`} />
          <CountdownCircleAnimation className={`absolute !top-[75%] !left-[50%] translate-x-[-50%] translate-y-[-50%] text-center h-full !z-[999999]`} />
        </>
      )}
      <div>{children}</div>
    </div>
  );
};

export default GameBlocker;
