import React, { useCallback, useEffect, useState } from "react";
import { shuffleArray } from "../../../../common/helpers/commonHelper";
import styles from "./WinGoBetArea.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../store/slices/gameSlice";
import GameConstant from "../../../../common/constants/gameConstant";

const bounceArr = shuffleArray(10);

const WinGoBetArea = () => {
  const dispatch = useDispatch();
  const { gameTime, gameBlock, betOption } = useSelector(
    (state) => ({
      gameTime: state.game.gameTime,
      gameBlock: state.game.gameBlock,
      betOption: state.game.betOption,
    }),
    shallowEqual
  );
  const [isBouncing, setIsBouncing] = useState(false);

  const updateBetOption = (field, value) => dispatch(gameAction.setBetOption({ field, value }));

  const handleBetClick = useCallback(
    (value, item = null) => {
      if (value) {
        dispatch(gameAction.setDrawer({ open: true, title: `Bet ${item.value}` }));
        dispatch(
          gameAction.setBetList({
            join: item.joinValue ? item.joinValue : item.value,
            color: item.color ? item.color : item.value.toLowerCase(),
          })
        );
      }
      // dispatch(gameAction.setDrawer({ open: value }));
    },
    [dispatch]
  );

  const showBounceEffect = useCallback(() => {
    setIsBouncing(true);
    setTimeout(() => {
      setIsBouncing(false);
      const ranNum = Math.floor(Math.random() * 10);
      handleBetClick(true, GameConstant.WinGo.BetList.numberBet[ranNum]);
    }, 4000);
  }, [handleBetClick]);

  const handleSetTimer = useCallback(
    (value) => {
      if (value !== 0) {
        updateBetOption("moneyQty", value);
      } else if (value === 0 && !isBouncing) {
        showBounceEffect();
      }
    },
    [dispatch, isBouncing, showBounceEffect]
  );

  useEffect(() => {
    if (gameBlock) {
      dispatch(gameAction.setDrawer({ open: false }));
    }
  }, [gameBlock, dispatch]);

  useEffect(() => {
    if (gameTime) {
      handleSetTimer(1);
    }
  }, [gameTime]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.colorBetRow}>
          {GameConstant.WinGo.BetList.colorBet.map((item, i) => {
            return (
              <div className={`${item.value.toLowerCase()} ${styles.colorBet}`} key={`colorbet-${i}`} onClick={() => handleBetClick(true, item)}>
                {item.value}
              </div>
            );
          })}
        </div>
        <div className={styles.numBetWrapper}>
          {GameConstant.WinGo.BetList.numberBet.map((item, i) => {
            return (
              <div className={styles.numBetItem} key={`numbet-${i}`}>
                <div className={`numBet ${item.color} ${isBouncing ? `bounce${bounceArr[i]}` : ""}`} onClick={() => handleBetClick(true, item)}>
                  {item.value}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.timerRow}>
          {GameConstant.WinGo.BetList.timerBet.map((item, i) => {
            return (
              <div
                className={`${styles.timerBet} ${item.value === 0 ? styles.redTimer : ""} ${item.value === betOption.moneyQty ? styles.activeTimer : ""}`}
                key={`timerbet-${i}`}
                onClick={() => handleSetTimer(item.value)}
              >
                {item.value === 0 ? "Random" : `X${item.value}`}
              </div>
            );
          })}
        </div>
        <div className={styles.bigSmallRow}>
          {GameConstant.WinGo.BetList.bigSmallBet.map((item, i) => {
            return (
              <div className={`${item.color} ${styles.bigSmallBet}`} key={`bigsmallbet-${i}`} onClick={() => handleBetClick(true, item)}>
                {item.value}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WinGoBetArea;
