import React from "react";
import styles from "./TotalNumber.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../../store/slices/gameSlice";
import CommonConstant from "../../../../../common/constants/commonConstant";
import GameConstant from "../../../../../common/constants/gameConstant";

const TotalNumber = () => {
  // Store
  const dispatch = useDispatch();

  const { betList: betListData, drawer } = useSelector(
    (state) => ({
      betList: state.game.betList,
      drawer: state.game.drawer,
    }),
    shallowEqual
  );

  const betList = betListData || [];

  // Game logic handling function
  const updateBet = (number) => {
    if (!drawer.open) dispatch(gameAction.setDrawer({ open: true }));
    // Remove the bet number from list if it exists
    if (betList.includes(number)) {
      let idx = betList.indexOf(number);
      if (idx > -1) {
        let clonedBetList = [...betList];
        clonedBetList.splice(idx, 1);

        // Handle drawer close
        if (clonedBetList.length === 0) dispatch(gameAction.setDrawer({ open: false }));

        dispatch(gameAction.setBetList(clonedBetList));
      }
    } else {
      dispatch(gameAction.setBetList([...betList, number]));
    }
  };

  // Render
  return (
    <div className={styles.betWrapper}>
      {CommonConstant.K3.GameSections[GameConstant.K3.GameType.TotalNumber].map((item, index) => {
        const selected = betList.includes(item.name);
        return (
          <div key={`totalNumber=${index}`} className={`${styles.betItem}`} onClick={() => updateBet(item.name)}>
            <div className={`${styles.betInnerItem} ${selected && styles.bgPrimary}`}>
              <div className={`${styles.betNames} ${selected && styles.textWhite} !text-md sm:!text-lg`}>{item.name}</div>
              <div className={`${styles.betOdds} ${selected && styles.textWhite} !text-xs sm:!text-sm flex flex-col sm:flex-row`}>
                <span>odds:</span> <span>{item.odds}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TotalNumber;
