import React from "react";
import styles from "./NoData.module.css";
import ImageConstant from "../../../common/constants/imageConstant";

const NoData = () => {
  return (
    <div className={styles.noData}>
      <img src={ImageConstant.noData} alt="nodata" width={182} height={177} />
      <div>No results</div>
    </div>
  );
};

export default NoData;
