import React from "react";
import { Checkbox, FormControlLabel, Modal } from "@mui/material";
import styles from "./BetResultOverlay.module.css";
import { formatToCurrency, objectIsNotEmpty } from "../../../common/helpers/commonHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../common/constants/gameConstant";
import ImageConstant from "../../../common/constants/imageConstant";
import IconCheckBox from "../../icon/IconCheckbox";
import { gameAction } from "../../../store/slices/gameSlice";
import useMutePopUp from "../../hook/useMutePopUp";
import CommonConstant from "../../../common/constants/commonConstant";

const BetResultOverlay = () => {
  const { popUpMuted, handleMutePopUp } = useMutePopUp(CommonConstant.Storage.MuteResultPopUp);
  const dispatch = useDispatch();
  const { betResult, game, gameTime, gameResult } = useSelector(
    (state) => ({
      betResult: state.game.betResult,
      game: state.game.game,
      gameTime: state.game.gameTime,
      gameResult: state.game.gameResult,
    }),
    shallowEqual
  );

  const { show, winAmount, periodId, resultSummary } = betResult;

  const WinGoResult = () => {
    const Container = ({ color, result, extraClasses }) => <span className={`${color} rounded-md px-2 ${extraClasses}`}>{result}</span>;
    const color = GameConstant.WinGo.BetList.numberBet[gameResult]?.color;
    return objectIsNotEmpty(resultSummary) ? (
      <div className="flex justify-evenly gap-2 flex-1">
        <Container color={color} result={resultSummary.colors.join(" ")} extraClasses={"flex-1 tracking-tight whitespace-nowrap capitalize"} />
        <Container color={color} result={resultSummary.fullResult} extraClasses={"flex-1"} />
        <Container color={color} result={resultSummary.size} extraClasses={"flex-1 capitalize"} />
      </div>
    ) : (
      <Container color={color} result={resultSummary.fullResult} />
    );
  };

  const K5dResult = () => {
    return (
      <div className={`result absolute rounded-lg p-1 top-[58%] left-[58%] !translate-x-[-50%] !translate-y-[-50%]`}>
        <div className="bg-[#025be8] rounded-lg p-1">
          {/* <div className="flex gap-2 justify-evenly">
            {SlotLabels.map((l, idx) => (
              <span key={idx} className="px-2">
                {l}
              </span>
            ))}
          </div> */}

          <div className="bg-[#003086] flex gap-1 p-1 rounded-lg">
            {gameResult?.split("").map((r, idx) => {
              const bgColor = idx === 0 ? "bg-[#51BC16]" : "bg-[#FFFFFF]";
              const txtColor = idx === 0 ? "text-[#FFFFFF]" : "text-[#000000]";
              return (
                <div key={idx} className={`rounded-full px-3 py-[1px] ${bgColor}`}>
                  <span className={`font-extrabold text-[12px] ${txtColor}`}>{r}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const K3Result = () => {
    return (
      <div className={`result absolute rounded-lg px-2 py-2 top-[10%] left-[50%] !translate-x-[-50%] !translate-y-[-50%] ml-5 mt-4`}>
        <div className="flex gap-3">
          {gameResult?.split("").map((r, idx) => (
            <div className="w-10 h-10">
              <img key={idx} src={ImageConstant[`diceNum${r}`]} alt={`Dice Num ${r}`} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const BetResult = () => {
    return <>{game === GameConstant.Games.K3 ? <K3Result /> : game === GameConstant.Games.K5d ? <K5dResult /> : <WinGoResult />}</>;
  };

  return (
    <Modal open={show && !popUpMuted}>
      <div className={styles.container}>
        <div className={`relative ${styles["win-animate"]} flex flex-col justify-center items-center gap-1`}>
          <div className="pr-[10px]">
            <img src={ImageConstant.betResult} alt="Result" className="h-full w-[25rem] ml-[0.3rem] sm:ml-[0.5rem]" />
            <div className="absolute top-[35%] !translate-y-[-50%] text-center leading-8 w-full flex gap-2 mx-5">
              <div className="flex gap-2 mr-11 w-full ml-2 pr-1">
                <span>Lottery results: </span>
                <BetResult />
              </div>
            </div>
            <div className="absolute top-[48%] !translate-y-[-50%] left-[50%] !translate-x-[-50%] flex flex-col gap-1 mt-10 text-[#5F51D7]">
              <span className="font-bold text-lg text-center">Bonus</span>
              <span className="font-extrabold text-4xl text-center">{formatToCurrency(winAmount)}</span>
              <div className="font-semibold flex gap-2 whitespace-nowrap -mb-2 tracking-tight text-sm mr-1">
                <span>Period: </span>
                <span>
                  Win {gameTime} minute {periodId}
                </span>
              </div>
            </div>
            <div className="absolute bottom-[22%] left-[50%] !translate-x-[-50%] text-xs">Auto closing in 3 seconds</div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => handleMutePopUp(!popUpMuted)}
                checked={popUpMuted}
                icon={<IconCheckBox width={18} height={18} />}
                checkedIcon={<IconCheckBox width={18} height={18} isChecked={popUpMuted} />}
              />
            }
            label={<span className={styles.accentLabel}>Don't show result again</span>}
            className={styles.accentContainer}
          />
          <img src={ImageConstant.closeIcon} alt="close" onClick={() => dispatch(gameAction.closeResult())} />
        </div>
      </div>
    </Modal>
  );
};

export default BetResultOverlay;
