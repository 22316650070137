import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& .MuiDrawer-paper": {
      backgroundColor: "#1C2257",
      maxWidth: "700px",
      margin: "0 auto",
      color: "#FFFFFF",
      padding: "0px 0px 20px 0px",
      borderRadius: "20px 20px 0px 0px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "#151747",
      opacity: "0.8 !important",
    },
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      color: "#F5F5F5",
    },
    position: "relative",
    padding: "20px 20px 0px",
    boxSizing: "border-box",
  },
  downWrapper: {
    padding: "0px 20px 0px 20px",
  },
  closeBtn: {
    position: "absolute",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    minHeight: "300px",
    width: "100% !important",
    gap: "20px",
    height: "auto",
    "& section": {
      width: "100%",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
    "& .timer": {
      padding: "2px 10px",
      borderRadius: "5px",
      border: "1px solid #FFFFFF",
    },
  },
  actionBtn: {
    padding: "10px 0px !important",
    width: "100% !important",
  },
  balanceContainer: {
    textAlign: "center",
    marginTop: "5px",
  },
  balance: {
    width: "100% !important",
    fontSize: "12px",
    padding: "5px",
    border: "1px solid #FCBC00",
    borderRadius: "6px",
    color: "#FCBC00",
  },
}));
