import GameConstant from "../constants/gameConstant";

const GameMapper = {
  K3: {
    GameTypeValue: {
      [GameConstant.K3.GameType.TotalNumber]: "total",
      [GameConstant.K3.GameType.TwoDuplicateNumber]: "two-same",
      [GameConstant.K3.GameType.ThreeDuplicateNumber]: "three-same",
      [GameConstant.K3.GameType.DifferentNumber]: "unlike",
    },
    GameModeText: {
      total: "Total number",
      "any-two-same": "2 suitable numbers fit",
      "unique-two-same": "Unique pair of numbers",
      "any-three-same": "3 unique numbers",
      "unique-three-same": "3 identical numbers",
      "three-different": "3 different numbers",
      "three-consecutive": "3 consecutive numbers",
      "two-different": "2 different numbers",
    },
  },
  SignalRConnection: {
    RoundEnded: (game) => `${game}-round`,
    RoundHandled: (game) => `${game}-post-round`,
    PayoutCompleted: `latest-balance`,
  },
};

export default GameMapper;
