import { createContext, useState, useContext, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import styles from "./LoadingProvider.module.css";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
  },
}));

export const LoadingContext = createContext();

export default function LoadingProvider({ children }) {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState([]);

  const isLoading = useCallback((data, state = false, animation = null) => {
    if (state) {
      setLoadingData([data]);
      // if (animation !== null) setIsAnimation(true);
      // setAnimation(animation);
    } else {
      setLoadingData((oldArray) => oldArray.filter((x) => x !== data));
      // if (animation !== null) setIsAnimation(false);
      // setAnimation(null);
    }
  }, []);

  const value = { isLoading };

  return (
    <LoadingContext.Provider value={value}>
      <Modal
        open={loadingData.length > 0 ? true : false}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <div className={classes.container}>
          <div className={styles.loading}></div>
        </div>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext);
