import React from "react";
import styles from "./TwoDuplicateNumber.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../../store/slices/gameSlice";
import CommonConstant from "../../../../../common/constants/commonConstant";
import GameConstant from "../../../../../common/constants/gameConstant";
import { deepCloneArray } from "../../../../../common/helpers/commonHelper";

const TwoDuplicateNumber = () => {
  const dispatch = useDispatch();
  const { drawer, betList: betListData } = useSelector(
    (state) => ({
      drawer: state.game.drawer,
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const betList = betListData ? [...betListData] : [[], [[], []]];

  // Functions
  const updateBet = (val, mainSection, subSection = null) => {
    let tempBetList = deepCloneArray(betList);

    let arr = mainSection === 0 ? tempBetList[0] : tempBetList[1][subSection];

    let secondLvlChanged = mainSection === 1;
    let secondLvlItemRemoved = false;
    let firstLvlItemRemoved = false;

    if (arr.includes(val)) {
      arr.splice(arr.indexOf(val), 1);
      secondLvlItemRemoved = secondLvlChanged;
      firstLvlItemRemoved = !secondLvlChanged;
    } else {
      arr.push(val);
    }

    // Handle second section logic - remove the opposite element from checked
    if (secondLvlChanged) {
      let firstRowChanged = subSection === 0;
      let firstRowList = CommonConstant.K3.GameSections[GameConstant.K3.GameType.TwoDuplicateNumber].DoubleValues;
      let secondRowList = CommonConstant.K3.GameSections[GameConstant.K3.GameType.TwoDuplicateNumber].SingleValues;
      let targetIdx = firstRowChanged ? firstRowList.indexOf(val) : secondRowList.indexOf(val);
      if (targetIdx > -1) {
        let targetVal = firstRowChanged ? secondRowList[targetIdx] : firstRowList[targetIdx];
        if (targetVal) {
          let targetBetList = tempBetList[1][firstRowChanged ? 1 : 0];
          let idxToRmv = targetBetList.indexOf(targetVal);
          if (idxToRmv > -1) targetBetList.splice(idxToRmv, 1);
        }
      }
    }

    // Handle clear list
    let firstRowCleared = tempBetList[1][0].length === 0;
    let secondRowCleared = tempBetList[1][1].length === 0;
    let sectionOneCleared = tempBetList[0].length === 0;
    let sectionTwoCleared = firstRowCleared && secondRowCleared;
    let sectionOneSelected = tempBetList[0].length > 0;
    let firstRowSelected = tempBetList[1][0].length > 0;
    let secondRowSelected = tempBetList[1][1].length > 0;

    // Clear list logic
    if (sectionOneCleared) {
      if (
        (secondLvlItemRemoved && (secondRowCleared || firstRowCleared)) ||
        (firstLvlItemRemoved && ((firstRowSelected && secondRowCleared) || (secondRowSelected && firstRowCleared)))
      ) {
        tempBetList[1] = [[], []];
      }
    }

    // Handle drawer close
    handleDrawer(tempBetList);

    // Update stored bet list
    dispatch(gameAction.setBetList(tempBetList));
  };

  const handleDrawer = (tempBetList) => {
    let firstRowCleared = tempBetList[1][0].length === 0;
    let secondRowCleared = tempBetList[1][1].length === 0;
    let sectionOneCleared = tempBetList[0].length === 0;
    let sectionTwoCleared = firstRowCleared && secondRowCleared;
    let sectionOneSelected = tempBetList[0].length > 0;
    let firstRowSelected = tempBetList[1][0].length > 0;
    let secondRowSelected = tempBetList[1][1].length > 0;

    // Handle drawer close
    if (sectionOneCleared && sectionTwoCleared) dispatch(gameAction.setDrawer({ open: false }));

    if (!drawer.open) {
      if (sectionOneSelected || (firstRowSelected && secondRowSelected)) dispatch(gameAction.setDrawer({ open: true }));
    }
  };

  // Components
  const NumberRow = ({ values, itemClassName, mainSection, subSection = null }) => (
    <div className={styles.boxNumRow}>
      {values.map((value, index) => {
        let selected = false;
        if (mainSection === 0) selected = betList[0].includes(value);
        else selected = betList[1][subSection].includes(value);

        return (
          <div
            className={`${itemClassName} ${styles.numItem} ${selected ? styles.active : ""}`}
            key={`${value}-${index}`}
            onClick={() => updateBet(value, mainSection, subSection)}
          >
            {value}
          </div>
        );
      })}
    </div>
  );

  // Render
  return (
    <>
      <div className={styles.betWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 2 suitable numbers fit</div>
          <div className={styles.oddsTitle}>Odds: 13.83 </div>
        </div>
        <NumberRow
          values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.TwoDuplicateNumber].DoubleValues}
          itemClassName={styles.numbox1}
          mainSection={0}
        />
        <div className={styles.remark}>*If the drawn number matches the bet number, you will win. (Except three identical numbers)</div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose a unique pair of numbers</div>
          <div className={styles.oddsTitle}>Odds: 69.12 </div>
        </div>
        <NumberRow
          values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.TwoDuplicateNumber].DoubleValues}
          itemClassName={styles.numbox2}
          mainSection={1}
          subSection={0}
        />
        <NumberRow
          values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.TwoDuplicateNumber].SingleValues}
          itemClassName={styles.numbox3}
          mainSection={1}
          subSection={1}
        />
        <div className={styles.remark}>
          *Choose at least 1 pair of the same number and 1 different number to bet. If the drawn result matches the bet numbers, you will win.
        </div>
      </div>
    </>
  );
};

export default TwoDuplicateNumber;
