import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";

const Layout = ({ dynamicHeight }) => {
  return (
    <div className={styles.container} id="lottery">
      <div className={styles.contentContainer}>
        <div className={`${styles.wrapper} ${dynamicHeight ? styles.wrapper2 : ""}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
