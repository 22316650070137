import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  gameRulesContainer: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#fafafa",
    maxHeight: "400px",
    "& ol": {
      paddingLeft: "20px",
      counterReset: "item",
      listStyle: "none",
      "& li": {
        counterIncrement: "item",
        position: "relative",
        paddingBottom: "10px",
        paddingTop: "5px",
        "&:before": {
          content: 'counter(item) ".0 "',
          fontWeight: "bold",
          marginRight: "5px",
        },
        "& ol": {
          counterReset: "subitem",
          listStyle: "none",
          "& li": {
            counterIncrement: "subitem",
            position: "relative",

            "&:before": {
              content: 'counter(item) "." counter(subitem) " "',
              fontWeight: "normal",
              marginRight: "5px",
            },
          },
        },
      },
    },
  },
  gameRulesContainer5d: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#fafafa",
    maxHeight: "400px",
    "& ol": {
      paddingLeft: "20px",
      counterReset: "item",
      listStyle: "none",
      "& li": {
        counterIncrement: "item",
        position: "relative",
        paddingBottom: "10px",
        paddingTop: "5px",
      },
    },
  },
  gameRulesContainerwingo: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#fafafa",
    maxHeight: "400px",
    "& ol": {
      paddingLeft: "20px",
      counterReset: "item",
      listStyle: "none",
      "& li": {
        counterIncrement: "item",
        position: "relative",
        paddingBottom: "10px",
        paddingTop: "5px",
        "&:before": {
          content: 'counter(item) ".0 "',
          fontWeight: "bold",
          marginRight: "5px",
        },
        "& ol": {
          counterReset: "subitem",
          listStyle: "none",
          "& li": {
            counterIncrement: "subitem",
            position: "relative",

            "&:before": {
              content: 'counter(item) "." counter(subitem) " "',
              fontWeight: "normal",
              marginRight: "5px",
            },
          },
        },
      },
    },
  },
  winningNoticeswitch: {
    "& .MuiSwitch-track": {
      backgroundColor: "#ffffff",
    },
  },
}));
