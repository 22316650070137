import React from "react";
import CustomTab from "../../../common/customTab/CustomTab";
import CommonConstant from "../../../../common/constants/commonConstant";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../../common/constants/gameConstant";
import { gameAction } from "../../../../store/slices/gameSlice";
import k3Style from "../K3Game.module.css";
import TotalNumber from "../gameType/totalNumber/TotalNumber";
import TwoDuplicateNumber from "../gameType/twoDuplicateNumber/TwoDuplicateNumber";
import ThreeDuplicateNumber from "../gameType/threeDuplicateNumber/ThreeDuplicateNumber";
import DifferentNumber from "../gameType/differentNumber/DifferentNumber";

const K3BetArea = () => {
  const dispatch = useDispatch();
  const { gameType } = useSelector(
    (state) => ({
      gameType: state.game.gameType,
    }),
    shallowEqual
  );

  const handleTabChange = (tab) => {
    dispatch(gameAction.clearBetList());
    dispatch(gameAction.setDrawer({ open: false }));
    dispatch(gameAction.setGameType(tab));
  };

  const GamesContent = () => {
    switch (gameType) {
      case GameConstant.K3.GameType.TotalNumber:
        return <TotalNumber />;
      case GameConstant.K3.GameType.TwoDuplicateNumber:
        return <TwoDuplicateNumber />;
      case GameConstant.K3.GameType.ThreeDuplicateNumber:
        return <ThreeDuplicateNumber />;
      case GameConstant.K3.GameType.DifferentNumber:
        return <DifferentNumber />;
      default:
        return null;
    }
  };

  return (
    <>
      <CustomTab
        tabList={CommonConstant.K3.GameTypes}
        tabValue={gameType || GameConstant.K3.GameType.TotalNumber}
        handleTabChange={handleTabChange}
        selectedTabStyle={"customK3Tabs"}
      />
      <div className={k3Style.gamebox}>
        <GamesContent />
      </div>
    </>
  );
};

export default K3BetArea;
