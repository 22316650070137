import React, { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { enqueueSnackbar } from "notistack";

import { useLoading } from "../common/provider/LoadingProvider";
import * as authEvent from "../../event/authEvent";
import { useDispatch } from "react-redux";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isLoading } = useLoading();
  const runOnce = useRef(true);

  const params = useMemo(() => {
    return queryString.parse(search);
  }, [search]);

  useEffect(() => {
    isLoading("AuthLoading", true);
    (async () => {
      if (runOnce.current) {
        let { game, session: sessionId } = params;
        if (!sessionId || !game) {
          return;
        }

        try {
          var { payload: redirectUrl } = await dispatch(authEvent.authUser({ sessionId, game }));
          setTimeout(() => {
            isLoading("AuthLoading", false);
            redirectUrl && navigate(redirectUrl);
          }, 500);
        } catch (error) {
          isLoading("AuthLoading", false);
          enqueueSnackbar(error);
        }
      }
    })();

    return () => {
      runOnce.current = false;
    };
  }, []);

  return <></>;
};

export default Auth;
