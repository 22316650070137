import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../common/constants/gameConstant";
import ImageConstant from "../../common/constants/imageConstant";
import K3Style from "../game/k3/K3Game.module.css";
import * as gameEvent from "../../event/gameEvent";
import CustomTab from "../common/customTab/CustomTab";
import CommonConstant from "../../common/constants/commonConstant";
import GameRecordList from "../common/gameRecordList/GameRecordList";
import { gameAction } from "../../store/slices/gameSlice";
import { processBetNumWingo } from "../../common/constants/winGoConstant";
import { useLoading } from "../common/provider/LoadingProvider";

const BaseRecord = () => {
  const dispatch = useDispatch();
  const { game, gameTime, gameInfo, recordType, loading } = useSelector(
    (state) => ({
      game: state.game.game,
      gameTime: state.game.gameTime,
      gameInfo: state.game.gameInfo,
      recordType: state.game.recordType,
      loading: state.game.loading,
    }),
    shallowEqual
  );

  const {
    [GameConstant.RecordType.BetHistory]: { items: betHistoryData, numberOfPages: betHistoryLastPage } = {},
    [GameConstant.RecordType.RoundHistory]: { items: roundHistoryData, numberOfPages: roundHistoryLastPage } = {},
  } = gameInfo;

  const processRoundHistoryData = () => {
    const processWinGoRoundHistory = () => {
      if (roundHistoryData?.length > 0) {
        const dataList = roundHistoryData.map((record) => {
          const betSize = record.result < 5 ? "Small" : "Big";
          const quantity = [record.result, record.result % 2 === 0 ? "#f43e23" : "#51bc16"];
          let color = [GameConstant.WinGo.BetList.numberBet[record.result].color];
          if (record.result === 0) {
            color = ["red", "violet"];
            quantity[1] = "#f43e23";
          } else if (record.result === 5) {
            color = ["green", "violet"];
            quantity[1] = "#51bc16";
          }

          return {
            period: record.period,
            quantity: quantity,
            betSize: betSize,
            color: color,
          };
        });
        const columns = dataList.length > 0 ? Object.keys(dataList[0]) : [];

        return { columns, dataList };
      }
      return { columns: [], dataList: [] };
    };

    const processK3RoundHistory = () => {
      if (roundHistoryData) {
        const dataList = roundHistoryData?.map((record) => {
          const total = String(record.result).split("");
          let totalSum = `${record.result}`.split("").reduce((acc, curr) => acc + Number(curr), 0);
          const betSize = totalSum >= 3 && totalSum <= 10 ? "Small" : "Big";
          const parity = totalSum % 2 === 0 ? "Even" : "Odd";
          return {
            period: record.period || "",
            total: totalSum || 0,
            betSize: betSize || "",
            parity: parity || "",
            result:
              total.map((digit, i) => (
                <div key={i}>
                  <img src={ImageConstant[`diceNum${digit}`]} alt={`Dice Num ${digit}`} className={K3Style.diceImg} />
                </div>
              )) ?? [],
          };
        });
        const columns = dataList.length > 0 ? Object.keys(dataList[0]) : [];
        return { columns, dataList };
      }
      return { columns: [], dataList: [] };
    };

    const processK5dRoundHistory = () => {
      if (roundHistoryData?.length > 0) {
        const dataList = roundHistoryData.map(({ period, result }) => ({
          period,
          result,
          total: `${result}`.split("").reduce((acc, curr) => acc + Number(curr), 0),
        }));
        const columns = dataList.length > 0 ? Object.keys(dataList[0]) : [];

        return { columns, dataList };
      }
      return { columns: [], dataList: [] };
    };

    var result = { columns: [], dataList: [] };
    if (game === GameConstant.Games.K3) result = processK3RoundHistory();
    if (game === GameConstant.Games.K5d) result = processK5dRoundHistory();
    if (game === GameConstant.Games.WinGo) result = processWinGoRoundHistory();
    return { columns: result?.columns || [], dataList: result?.dataList || [] };
  };

  const processBetHistoryData = () => {
    const processWinGoBetHistory = () => {
      if (betHistoryData?.length > 0) {
        const newMyBetList = betHistoryData?.map((record) => {
          return {
            betNum: processBetNumWingo(record?.selection),
            ...record,
          };
        });
        return newMyBetList;
      }
      return [];
    };

    let betHistory = betHistoryData;
    if (game === GameConstant.Games.WinGo) betHistory = processWinGoBetHistory();
    betHistory ||= [];
    return betHistory;
  };

  const handleRecordTabChange = async (tab) => {
    if (loading) return;
    dispatch(gameAction.setRecordType(tab));
    dispatch(gameAction.setLoading(true));
    if (tab === GameConstant.RecordType.RoundHistory) await fetchRoundHistory();
    else if (tab === GameConstant.RecordType.BetHistory) await fetchBetHistory();
    dispatch(gameAction.setLoading(false));
  };

  const fetchBetHistory = async (pageNumber = 1) => await dispatch(gameEvent.getBetHistory({ game, gameTime, pageNumber }));
  const fetchRoundHistory = async (pageNumber = 1) => await dispatch(gameEvent.getRoundHistory({ game, gameTime, pageNumber }));

  return (
    <>
      <CustomTab tabList={CommonConstant.RecordTypes} tabValue={recordType} handleTabChange={handleRecordTabChange} selectedTabStyle={"customRecordTabs"} />
      <GameRecordList
        activeTab={recordType}
        dataHeader={CommonConstant.TableHeaders[game]}
        dataColumns={processRoundHistoryData().columns}
        gameDataList={processRoundHistoryData().dataList}
        myBetList={processBetHistoryData()}
        totalPages={recordType === GameConstant.RecordType.BetHistory ? betHistoryLastPage : roundHistoryLastPage}
        fetchRoundHistory={fetchRoundHistory}
        fetchBetHistory={fetchBetHistory}
        selectedGameTime={gameTime}
      />
    </>
  );
};
export default BaseRecord;
