import React, { memo } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import styles from "./CustomTab.module.css";

const useStyle = makeStyles((theme) => ({
  customTabs: {
    "& .MuiTabs-flexContainer": {
      backgroundColor: "#0d1134",
      borderRadius: 8,
      padding: 4,
    },
    "& button": {
      background: "transparent",
      color: "#939bb1",
      fontSize: 13,
      textTransform: "none",
      border: "1px solid transparent",
      borderRadius: 8,
      padding: 8,
      minHeight: "auto",
    },
    "& button.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#1c2257",
      border: "1px solid #2d346e",
    },
    "& .MuiTabs-indicator": { display: "none" },
  },
  customK3Tabs: {
    "& .MuiTabs-flexContainer": {
      borderRadius: 8,
      padding: "4px 0px",
      minHeight: "25px",
      flexDirection: "row",
      overflowX: "auto",
      display: "flex",
    },
    "& button": {
      flex: "0 0 auto",
      background: "#FFFFFF1A",
      color: "#FFFFFF",
      textTransform: "none",
      border: "1px solid transparent",
      padding: "5px 10px",
      minHeight: "auto",
      width: "80px",
      margin: "0px 5px 0px 0px",
      borderRadius: "5px 5px 0px 0px",
      fontWeight: "500",
      fontSize: "12px",
      textAlign: "center",
    },
    "& button.Mui-selected": {
      backgroundColor: "#025BE8",
      color: "#FFFFFF",
    },
    "& .MuiTabs-indicator": { display: "none" },
  },
  customK5Tabs: {
    "& .MuiTabs-flexContainer": {
      borderRadius: 8,
      padding: "4px 0px",
      minHeight: "25px",
      flexDirection: "row",
      overflowX: "auto",
      display: "flex",
    },
    "& button": {
      flex: "0 0 auto",
      background: "#FFFFFF1A",
      color: "#FFFFFF",
      textTransform: "none",
      border: "1px solid transparent",
      padding: "5px 10px",
      minHeight: "auto",
      width: "45px",
      minWidth: "unset",
      margin: "0px 5px 0px 0px",
      borderRadius: "5px 5px 0px 0px",
      fontWeight: "500",
      fontSize: "12px",
      textAlign: "center",
    },
    "& button.Mui-selected": {
      backgroundColor: "#025BE8",
      color: "#FFFFFF",
    },
    "& .MuiTabs-indicator": { display: "none" },
  },
  customRecordTabs: {
    "& .MuiTabs-flexContainer": {
      padding: "4px",
      fontSize: "16px",
      textAlign: "center",
    },
    "& button": {
      color: "#939BB1",
      borderBottom: "1px solid #939BB1",
      fontWeight: "500",
    },
    "& button.Mui-selected": {
      color: "#FAFAFA",
      borderBottom: "1px solid #FFFFFF",
    },
    "& .MuiTabs-indicator": { display: "none" },
  },
}));

const CustomTab = ({ tabList, tabValue, handleTabChange, selectedTabStyle }) => {
  const classes = useStyle();
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(_, val) => handleTabChange(val)}
        variant="fullWidth"
        scrollButtons="auto"
        classes={{ root: selectedTabStyle ? classes[selectedTabStyle] : classes.customTabs }}
      >
        {tabList.map((tab) => (
          <Tab key={tab.value} label={<div className={styles.tabLabel}>{tab.name}</div>} value={tab.value} />
        ))}
      </Tabs>
    </>
  );
};

export default memo(CustomTab);
