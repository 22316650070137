import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import k3Style from "../K3Game.module.css";
import GameConstant from "../../../../common/constants/gameConstant";

const K3DrawerContent = () => {
  const { gameType, betList: betListData } = useSelector(
    (state) => ({
      gameType: state.game.gameType,
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const TotalNumberContent = () => {
    const betList = betListData || [];
    return (
      <div className={`${k3Style.betRoot} ${k3Style.game1}`}>
        <div className={k3Style.betWrapper}>
          <span className={k3Style.betLabel}>Total Number:</span>
          <div className={k3Style.betContainer}>
            {betList.map((num, idx) => (
              <span key={idx} className={k3Style.betNums}>
                {num}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const TwoDuplicateNumberContent = () => {
    const betList = betListData || [[], [[], []]];
    let section1Nums = betList?.[0] || [];
    let section2Nums1 = betList?.[1]?.[0] || [];
    let section2Nums2 = betList?.[1]?.[1] || [];

    return (
      <div className={`${k3Style.betRoot} ${k3Style.game2}`}>
        {section1Nums.length > 0 && (
          <div className={k3Style.betWrapper}>
            <span className={k3Style.betLabel}>Choose 2 suitable numbers fit:</span>
            <div className={k3Style.betContainer}>
              {section1Nums.map((val, idx) => (
                <span key={idx} className={k3Style.betNums}>
                  {val}
                </span>
              ))}
            </div>
          </div>
        )}

        {section2Nums1.length > 0 && section2Nums2.length > 0 && (
          <div className={k3Style.betWrapper}>
            <span className={k3Style.betLabel}>Choose a unique pair of numbers:</span>
            <div className={k3Style.betContainer}>
              {section2Nums1.map((val, idx) => {
                if (section2Nums2.length > 0) {
                  return (
                    <span key={idx} className={`${k3Style.betNums} ${k3Style.betNumsGradients}`}>
                      <span>
                        {val} | {section2Nums2.join(",")}
                      </span>
                    </span>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const ThreeDuplicateNumberContent = () => {
    const betList = betListData || { nums: [], checked: false };
    const selectedNums = betList?.nums || [];
    const btnChecked = betList?.checked || false;

    return (
      <div className={`${k3Style.betRoot} ${k3Style.game3}`}>
        {selectedNums.length > 0 && (
          <div className={k3Style.betWrapper}>
            <span className={k3Style.betLabel}>Choose 2 suitable numbers fit:</span>
            <div className={k3Style.betContainer}>
              {selectedNums.map((val, idx) => (
                <span key={idx} className={k3Style.betNums}>
                  {val}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className={k3Style.betWrapper}>{btnChecked && <div className={k3Style.betBtn}>Choose 3 identical numbers</div>}</div>
      </div>
    );
  };

  const DifferentNumberContent = () => {
    const betList = betListData || { nums1: [], nums2: [], checked: false };
    const selectedNums1 = betList?.nums1 || [];
    const selectedNums2 = betList?.nums2 || [];
    const btnChecked = betList?.checked || false;

    return (
      <div className={`${k3Style.betRoot} ${k3Style.game4}`}>
        {selectedNums1.length >= 3 && (
          <div className={k3Style.betWrapper}>
            <span className={k3Style.betLabel}>Choose 3 different numbers:</span>
            <div className={k3Style.betContainer}>
              {selectedNums1.map((val, idx) => (
                <span key={idx} className={k3Style.betNums}>
                  {val}
                </span>
              ))}
            </div>
          </div>
        )}
        {selectedNums2.length >= 2 && (
          <div className={k3Style.betWrapper}>
            <span className={k3Style.betLabel}>Choose 2 different numbers:</span>
            <div className={k3Style.betContainer}>
              {selectedNums2.map((val, idx) => (
                <span key={idx} className={k3Style.betNums}>
                  {val}
                </span>
              ))}
            </div>
          </div>
        )}
        <div className={k3Style.betWrapper}>{btnChecked && <div className={k3Style.betBtn}>Choose 3 consecutive numbers</div>}</div>
      </div>
    );
  };

  switch (gameType) {
    case GameConstant.K3.GameType.TotalNumber:
      return <TotalNumberContent />;
    case GameConstant.K3.GameType.TwoDuplicateNumber:
      return <TwoDuplicateNumberContent />;
    case GameConstant.K3.GameType.ThreeDuplicateNumber:
      return <ThreeDuplicateNumberContent />;
    case GameConstant.K3.GameType.DifferentNumber:
      return <DifferentNumberContent />;
    default:
      return null;
  }
};

export default K3DrawerContent;
