import React, { useEffect, useState } from "react";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { shallowEqual, useSelector } from "react-redux";

const CountdownCircleAnimation = ({ ...props }) => {
  const { nextCron, gameTime } = useSelector(
    (state) => ({
      nextCron: state.game.nextCron,
      gameTime: state.game.gameTime,
    }),
    shallowEqual
  );

  const [duration, setDuration] = useState(5);

  useEffect(() => {
    const { minutes, seconds } = nextCron?.[gameTime] || { minutes: 0, seconds: 0 };
    if (minutes === 0 && seconds <= 5) {
      setDuration(seconds);
    }
  }, [nextCron, gameTime]);

  return (
    <div {...props}>
      <CountdownCircleTimer
        isPlaying
        duration={duration <= 4 ? duration + 1 : duration}
        colors={["#9922be", "#643ebe", "#265ebe", "#24a4d5", "#23e7eb", "#23e7eb"]}
        colorsTime={[5, 4, 3, 2, 1, 0]}
        style={{ zIndex: "101 !important" }}
      >
        {({ remainingTime }) => {
          return <p style={{ color: "#FFFFFF", fontSize: "150px", fontWeight: "700" }}>{remainingTime}</p>;
        }}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountdownCircleAnimation;
