import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: "20px",
    fontSize: "14px !important",
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .label": {
      color: "#B0BBCC",
    },
    "& .opt-1": {
      display: "flex",
      justifyContent: "space-between",
      gap: "15px",
      "& .opt-item": {
        cursor: "pointer",
        minWidth: "50px !important",
        border: "1px solid #025BE8",
        borderRadius: "8px",
        textAlign: "center",
        padding: "5px 0px",
      },
      "& .opt-item.active": {
        backgroundColor: "#025BE8",
      },
    },
    "&.no-label": {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      "& .opt-item": {
        cursor: "pointer",
        flex: "1 1 0%",
        textAlign: "center",
        border: "1px solid #025BE8",
        borderRadius: "8px",
        padding: "5px 0px",
        "&.active": {
          backgroundColor: "#025BE8",
        },
      },
    },
  },
  counterRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  counterBtn: {
    cursor: "pointer",
    width: "20px",
    height: "20px",
    textAlign: "center",
    borderRadius: "3px",
    backgroundColor: "#025BE8",
    color: "white",
    "&.disabled": {
      opacity: ".5",
    },
  },
  counterNum: {
    width: "35px",
    padding: "0",
    textAlign: "center",
    border: "none",
    "& .MuiInputBase-root": {
      color: "#ffffff !important",
    },
    "& .MuiInputBase-input": {
      padding: "2px !important",
      textAlign: "center !important",
    },
  },
  consentLink: {
    color: "#FCBC00",
  },
  consentRoot: {
    display: "flex",
    alignItems: "center",
    color: "#939BB1",
    marginTop: "-20px",
  },
  consentCheck: {
    color: "unset !important",
    borderRadius: "15px !important",
    "&.Mui-checked": {
      color: "#025BE8 !important",
    },
  },
  checkIcon: {
    color: "#FFFFFF",
  },
  confirmBtn: {
    backgroundColor: "#025BE8 !important",
    padding: "10px 0px !important",
    marginBottom: "10px!important",
  },
  presalesContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#fafafa",
    maxHeight: "400px",
    "& ol": {
      paddingLeft: "20px",
      counterReset: "item",
      listStyle: "none",
      "& li": {
        counterIncrement: "item",
        position: "relative",
        paddingBottom: "10px",
        paddingTop: "5px",
        "&:before": {
          content: 'counter(item) ".0 "',
          fontWeight: "bold",
          marginRight: "5px",
        },
        "& ol": {
          counterReset: "subitem",
          listStyle: "none",
          "& li": {
            counterIncrement: "subitem",
            position: "relative",

            "&:before": {
              content: 'counter(item) "." counter(subitem) " "',
              fontWeight: "normal",
              marginRight: "5px",
            },
          },
        },
      },
    },
  },
}));
