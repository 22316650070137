import React, { useEffect } from "react";
import styles from "./K5dBetArea.module.css";
import CustomTab from "../../../common/customTab/CustomTab";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../store/slices/gameSlice";
import GameConstant from "../../../../common/constants/gameConstant";
import CommonConstant from "../../../../common/constants/commonConstant";

const K5dBetArea = ({ ...props }) => {
  const dispatch = useDispatch();
  const {
    gameType,
    drawer,
    betList: betListData,
  } = useSelector(
    (state) => ({
      gameType: state.game.gameType,
      drawer: state.game.drawer,
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const betList = betListData || { checked: null, nums: [] };

  // Reducer actions
  const setBetList = (betList) => dispatch(gameAction.setBetList(betList));
  const setDrawerOpen = (status) => dispatch(gameAction.setDrawer({ open: status }));

  // Functions
  const handleTabChange = (newTab) => {
    if (newTab) dispatch(gameAction.setGameType(newTab));
  };

  const handleBetClick = (type, value) => {
    if (!drawer.open) setDrawerOpen(true);
    let prevBet = { ...betList };

    if (type === "checked") {
      prevBet["checked"] = value;
    } else {
      // remove if exists
      if (prevBet["nums"].includes(value)) {
        let clonedNums = [...prevBet["nums"]];
        clonedNums.splice(clonedNums.indexOf(value), 1);
        prevBet["nums"] = clonedNums;
      } else {
        prevBet["nums"] = [...prevBet["nums"], value];
      }
    }

    // Clear either section if another section selected
    if (type === "checked") prevBet["nums"] = [];
    else if (type === "nums" && prevBet["nums"].length > 0) prevBet["checked"] = null;

    if (type === "nums" && prevBet["nums"].length === 0) setDrawerOpen(false);
    setBetList(prevBet);
  };

  useEffect(() => {
    if (gameType === GameConstant.K5d.GameType.Total) {
      setBetList({ ...betList, nums: [] });
    }
  }, [gameType]);

  // Render
  return (
    <div className={styles.root} {...props}>
      <CustomTab tabList={CommonConstant.K5d.GameTypes} tabValue={gameType || "a"} handleTabChange={handleTabChange} selectedTabStyle={"customK5Tabs"} />
      <div className={styles.gamebox}>
        {/* Bet group 1 */}
        <div className={styles.btnGroup1}>
          {CommonConstant.K5d.GameSections.Section1.map((item, idx) => (
            <span
              key={idx}
              className={`${betList["checked"] === item.value && styles.active} whitespace-nowrap`}
              onClick={() => handleBetClick("checked", item.value)}
            >
              {item.name}
            </span>
          ))}
        </div>
        {/* Bet group 2 */}
        {gameType !== GameConstant.K5d.GameType.Total && (
          <div className={styles.btnGroup2}>
            {CommonConstant.K5d.GameSections.Section2.map((num, idx) => {
              return (
                <div key={idx} onClick={() => handleBetClick("nums", num)}>
                  <div className={`${betList["nums"].includes(num) && styles.active}`}>{num}</div>
                  <span>{CommonConstant.K5d.NumberBetOddValue}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default K5dBetArea;
