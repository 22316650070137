import CommonConstant from "../constants/commonConstant";

export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const getLocalStorage = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value === null || value === "undefined") return undefined;
    return JSON.parse(value);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const deleteFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
};

//#region User
export const getAccessToken = () => getLocalStorage(CommonConstant.Storage.AccessToken);

export const setAccessToken = (value) => {
  setLocalStorage(CommonConstant.Storage.AccessToken, value);
};

// export const getRefreshToken = () => getLocalStorage(CommonConstant.Storage.RefreshToken);

// export const setRefreshToken = (value) => {
//   setLocalStorage(CommonConstant.Storage.RefreshToken, value);
// };

// export const isUserLoggedIn = () => !!getLocalStorage(CommonConstant.Storage.AccessToken);

export const deleteAccessToken = () => deleteFromLocalStorage(CommonConstant.Storage.AccessToken);

// export const deleteRefreshToken = () =>
//   deleteFromLocalStorage(CommonConstant.Storage.RefreshToken);

// export const deleteExpiredTime = () =>
//   deleteFromLocalStorage(CommonConstant.Storage.ExpiredTime);

export const getUsername = () => getLocalStorage(CommonConstant.Storage.Username);
export const setUsername = (value) => setLocalStorage(CommonConstant.Storage.Username, value);
export const deleteUsername = () => deleteFromLocalStorage(CommonConstant.Storage.Username);

export const getSignalRId = () => getLocalStorage(CommonConstant.Storage.SignalRId);
export const setSignalRId = (value) => setLocalStorage(CommonConstant.Storage.SignalRId, value);
export const deleteSignalRId = () => deleteFromLocalStorage(CommonConstant.Storage.SignalRId);

/**
 * @typedef TStorageCountry
 * @type {object} 
 * @property {string} iso2
 * @property {string} currency
 */
/**
 * @returns {TStorageCountry}
 */
export const getCountry = () => getLocalStorage(CommonConstant.Storage.Country);

/**
 * @param {TStorageCountry} value
 */
export const setCountry = (value) => setLocalStorage(CommonConstant.Storage.Country, value);
export const deleteCountry = () => deleteFromLocalStorage(CommonConstant.Storage.Country);
//#endregion
