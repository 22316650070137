import BetConstant from "./betConstant";

const K3Constant = {
  BetMapping: {
    Big: BetConstant.BetSize.Big,
    Small: BetConstant.BetSize.Small,
    Odd: BetConstant.BetPattern.Odd,
    Even: BetConstant.BetPattern.Even,
  },
  BetCheckedStatus: {
    Active: "y",
    Inactive: "n",
  },
  GameTypes: {
    Total: "total",
    TwoSame: "two-same",
    ThreeSame: "three-same",
    Different: "unlike",
  },
  GameModes: {
    Total: "total",
    TwoSame: {
      AnyTwoSame: "any-two-same",
      UniqueTwoSame: "unique-two-same",
    },
    ThreeSame: {
      AnyThreeSame: "any-three-same",
      UniqueThreeSame: "unique-three-same",
    },
    Different: {
      ThreeDifferent: "three-different",
      ThreeConsecutive: "three-consecutive",
      TwoDifferent: "two-different",
    },
  },
};

export default K3Constant;
