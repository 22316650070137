import axios from "axios";
import ApiConstant from "../common/constants/apiConstant";

const base = ApiConstant.Game.Base;

export const getGameResult = (game, gameTime) => axios.post(`${base}${ApiConstant.Game.GetGameResult}`, { game, gameTime });
export const getPrevRoundWinnings = (game, gameTime) => axios.post(`${base}${ApiConstant.Game.GetPrevRoundWinnings}`, { game, gameTime });
export const getUserGameInfo = (game, gameTime) => axios.post(`${base}${ApiConstant.Game.GetUserGameInfo}`, { game, gameTime });
export const getRoundHistory = (game, gameTime, pageNumber) => axios.post(`${base}${ApiConstant.Game.GetRoundHistory}`, { game, gameTime, pageNumber });
export const getBetHistory = (game, gameTime, pageNumber) => axios.post(`${base}${ApiConstant.Game.GetBetHistory}`, { game, gameTime, pageNumber });
export const submitK3Bet = (data) => axios.post(`${base}${ApiConstant.Game.SubmitK3Bet}`, { ...data });
export const submitK5dBet = (data) => axios.post(`${base}${ApiConstant.Game.submitK5dBet}`, { ...data });
export const submitWinGoBet = (data) => axios.post(`${base}${ApiConstant.Game.SubmitWinGoBet}`, { ...data });
export const getBalance = () => axios.post(`${base}${ApiConstant.Game.GetBalance}`);
