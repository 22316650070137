import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paginationWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  paginationButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#1C2257",
    margin: "0px 10px",
  },
  paginationResult: {
    fontSize: "12px",
    fontWeight: "300",
  },
});
const Pagination = (props) => {
  const { currentPage, totalPages, onPrevious, onNext } = props;
  const classes = useStyles();

  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.paginationButton} onClick={onPrevious}>
        <NavigateBeforeIcon />
      </div>
      <div className={classes.paginationResult}>
        {currentPage}/{totalPages}
      </div>
      <div className={classes.paginationButton} onClick={onNext}>
        <NavigateNextIcon />
      </div>
    </div>
  );
};

export default Pagination;
