import GameConstant from "../../../common/constants/gameConstant";

const gameRulesData = {
  [GameConstant.Games.K3]: {
    title: "Game rules",
    content: [
      "In each lottery period, three numbers will be randomly drawn, ranging from 111 to 666, excluding the number 0. These numbers are not in a specific order. If your chosen set of three numbers matches all or part of the drawn numbers, you win a prize.",
      "Total Bet: Bet on the total sum of the three dice.",
      "Full Set Bet: Place a bet on a group of three identical numbers (e.g., 111, 222, ..., 666) covering the entire set.",
      "Single Set Bet: Choose any set of three numbers (e.g., 111 to 666) and place a bet.",
      "Two Pairs Bet: Place a bet on three numbers, consisting of two specified pairs and one additional random number.",
      "Mixed Pair + One Single Bet: Place a bet on three numbers, with two specified pairs and one additional different number.",
      "Bet on 3 Different Numbers: Place a bet on three entirely different numbers.",
      "Bet on 2 Different Numbers: Place a bet on two different numbers plus one additional different number, all combined in one bet.",
      "Bet on 3 Consecutive Numbers: Place a bet on the entire set of consecutive numbers [123, 234, 345, 456].",
    ],
  },
  [GameConstant.Games.K5d]: {
    title: "Game rules",
    content: [
      "A randomly drawn 5-digit number (00000-99999) defines the outcome.Bets must adhere to the rule of not allowing two-sided bets within a single game period (e.g., choosing both odd and even or high and low), and for number bets, the maximum total selected numbers is limited to 7 per period. If you place more than 7 bets, turnover will be considered invalid and you wont get refunded.",
      "For example: The draw number for this Period is 12345 (A=1, B=2, C=3, D=4, E=5)",
      "SUM=A+B+C+D+E=15",
      "How to play: Players can specify six outcomes of betting A,B,C,D,E and the sum A,B,C,D,E can be purchased",
      "Number (0 1 2 3 4 5 6 7 8 9)",
      "Low(0 1 2 3 4)",
      "High(5 6 7 8 9)",
      "Odd(1 3 5 7 9)",
      "Even(0 2 4 6 8)",
      "SUM=A+B+C+D+E can be purchased",
      "Low (0-22)",
      "High (23-45)",
      "Odd(1 3 ...43 45)",
      "Even(0 2 ...42 44)",
    ],
  },
  [GameConstant.Games.WinGo]: {
    title: "Game rules",
    content: [
      "Players can only place one-sided bets in each game period. For example bet 100 INR after deduct the fee the exact betting amount will be 98 INR.",
      "Select Green: if the result shows 1,3,7,9 the pay out is  (98*2)=196 ; If the result shows 5, the pay out is  (98*1.5) 147",
      "Select Red: if the result shows 2,4,6,8 the pay out is  (98*2)=196 ; If the result shows 0, the pay out is  (98*1.5) 147",
      "Select Violet: if the result shows 0 or 5, the pay out is  (98*4.5)=441",
      "Select Number: if the result is the same as the number you selected, the pay out is  (98*9)=882",
      "Select Big: if the result shows 5,6,7,8,9 the pay out is  (98*2)=196",
      "Select Small: if the result shows 0,1,2,3,4 the pay out is  (98*2)=196",
    ],
  },
};

export default gameRulesData;
