const BetConstant = {
  BetColor: {
    Green: "green",
    Violet: "violet",
    Red: "red",
    MixRed: "mixred",
    MixGreen: "mixgreen",
  },
  BetPattern: {
    Odd: "odd",
    Even: "even",
  },
  BetSize: {
    Big: "big",
    Small: "small",
  },
  BetResult: {
    Pending: 0,
    Win: 1,
    Lose: 2,
    Cancelled: 3,
  },
};

export default BetConstant;
