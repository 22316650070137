const IconCheckBox = ({ width = 18, height = 14, isChecked = false }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 4.05705V9.94295C14 10.9655 13.6018 11.9268 12.8788 12.6498C12.7636 12.7311 11.8129 14 9.94295 14H4.05705C2.18734 14 1.23761 12.732 1.12123 12.6498C0.398207 11.9268 0 10.9655 0 9.94295V4.05705C0 3.0345 0.398207 2.07318 1.12123 1.35015C1.2364 1.26889 2.18712 0 4.05705 0H9.94295C11.8127 0 12.7624 1.26804 12.8788 1.35015C13.6018 2.07318 14 3.03453 14 4.05705ZM12.9063 4.05705C12.9063 3.32667 12.6218 2.64001 12.1054 2.12357C11.9856 2.03741 11.3204 1.09375 9.94295 1.09375H4.05705C2.68699 1.09375 2.01039 2.04031 1.89462 2.12357C1.37818 2.64001 1.09375 3.32667 1.09375 4.05705V9.94295C1.09375 10.6733 1.37818 11.36 1.89462 11.8764C2.01444 11.9626 2.67961 12.9063 4.05705 12.9063H9.94295C11.313 12.9063 11.9896 11.9597 12.1054 11.8764C12.6218 11.36 12.9063 10.6733 12.9063 9.94295V4.05705Z"
        fill="#ACACAC"
      />
      {isChecked && (
        <path
          d="M17.1533 0.000334176C16.9397 0.00628432 16.7369 0.0897425 16.588 0.233028L8.12363 8.14461L5.41568 5.6135C5.3399 5.53973 5.24914 5.48084 5.14871 5.44026C5.04828 5.39969 4.9402 5.37826 4.83079 5.37722C4.72139 5.37618 4.61286 5.39555 4.51157 5.43421C4.41027 5.47286 4.31825 5.53002 4.24088 5.60233C4.16352 5.67464 4.10237 5.76066 4.06101 5.85533C4.01966 5.95001 3.99893 6.05145 4.00004 6.15371C4.00116 6.25597 4.02409 6.357 4.06749 6.45087C4.1109 6.54474 4.17391 6.62958 4.25284 6.7004L7.54221 9.77496C7.69643 9.91905 7.90557 10 8.12363 10C8.34169 10 8.55082 9.91905 8.70505 9.77496L17.7508 1.31993C17.8696 1.21197 17.9508 1.07296 17.9837 0.921144C18.0165 0.769322 17.9996 0.611763 17.935 0.469115C17.8704 0.326467 17.7612 0.205383 17.6217 0.121733C17.4822 0.0380828 17.319 -0.00423182 17.1533 0.000334176Z"
          fill="#2875FF"
        />
      )}
    </svg>
  );
};

export default IconCheckBox;
