import React from "react";
import { useStyle } from "./GameRuleModal.style";
import { useSelector } from "react-redux";
import { selectGame } from "../../../store/slices/gameSlice";
import gameRulesData from "./gameRulesData";
import CommonPopUp from "../../common/commonPopUp/CommonPopUp";
const GameRuleModal = ({ show, toggle }) => {
  const classes = useStyle();
  const { game } = useSelector(selectGame);

  const gameRules = gameRulesData[game] || {};
  const { content } = gameRules;
  return (
    <CommonPopUp open={show} handleCloseModal={() => toggle()} title={"Game Rules"}>
      <div className={classes[game]}>
        {content && content.length > 0 && (
          <>
            <div className="pb-[10px]">{content[0]}</div>
            <ol>
              {content.slice(1).map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ol>
          </>
        )}
      </div>
    </CommonPopUp>
  );
};

export default GameRuleModal;
