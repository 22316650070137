import { Button, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useStyle } from "./CustomDrawer.style";
import { shallowEqual, useSelector } from "react-redux";
import GameConstant from "../../../common/constants/gameConstant";
import { formatToCurrency } from "../../../common/helpers/commonHelper";

const CustomDrawer = ({
  anchor = "bottom",
  open,
  onClose,
  onConfirm,
  showActionBtn = true,
  title,
  timer = 10,
  children,
  betSummary,
}) => {
  const classes = useStyle();
  const { gameType, betList, game, walletBalance } = useSelector(
    (state) => ({
      gameType: state.game.gameType,
      betList: state.game.betList,
      game: state.game.game,
      walletBalance: state.user.walletBalance,
    }),
    shallowEqual,
  );
  const [headerColor, setHeaderColor] = useState(null);

  const isWinGo = game === GameConstant.Games.WinGo;

  useEffect(() => {
    if (gameType === GameConstant.WinGo.GameType.WinGoBet) {
      setHeaderColor(betList?.color);
    }
  }, [gameType, betList]);

  return (
    <Drawer
      variant={isWinGo ? "temporary" : "persistent"}
      anchor={anchor}
      open={open}
      className={classes.root}
      onClose={() => isWinGo && onClose()}
      hideBackdrop={!isWinGo}
    >
      <div className={`${classes.header} ${headerColor}`}>
        <CloseIcon onClick={() => onClose()} className={classes.closeBtn} />
        <section className={classes.title}>
          <span className="timer">{timer} Minute</span>
          {isWinGo && title && <span>{title}</span>}
        </section>
      </div>
      <div className={classes.downWrapper}>
        <div className={classes.body}>
          <section>{children}</section>
          <section>{betSummary}</section>
        </div>
        <div className={classes.footer}>
          {showActionBtn && (
            <>
              <Button
                color="error"
                className={classes.actionBtn}
                variant="contained"
                onClick={(e) => onClose()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.actionBtn}
                onClick={(e) => onConfirm(e)}
              >
                Confirm
              </Button>
            </>
          )}
        </div>
        <div className={classes.balanceContainer}>
          <span className={classes.balance}>
            Current Balance : {formatToCurrency(walletBalance)}
          </span>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
