import { useEffect, useRef } from "react";
import { useSignalR } from "./useSignalR";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../store/slices/gameSlice";

const useGameSignalR = (connectionList) => {
  const dispatch = useDispatch();
  const { gameTime, game, globalBlock } = useSelector(
    (state) => ({
      gameTime: state.game.gameTime,
      game: state.game.game,
      globalBlock: state.game.globalBlock,
    }),
    shallowEqual
  );

  const { startConnection, stopConnection } = useSignalR();
  const connectionRef = useRef(null);

  if (connectionList && Array.isArray(connectionList) && gameTime) {
    connectionList.push({
      connectionName: "cron-time",
      callback: (cronTimers) => {
        dispatch(gameAction.setNextCron(cronTimers));
      },
    });
  }

  useEffect(() => {
    if (globalBlock.show) stopConnection();
  }, [globalBlock]);

  useEffect(() => {
    if (game) {
      (async () => {
        const connection = await startConnection(game, connectionList);
        connectionRef.current = connection;
        connectionRef.current?.invoke("SyncCronTimer");
      })();
    }

    return () => {
      stopConnection();
      connectionRef.current = null;
    };
  }, [game]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        connectionRef.current?.invoke("SyncCronTimer");
      } else {
        // Do nothing
      }
    };

    connectionRef.current?.invoke("SyncCronTimer");
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [game, gameTime]);

  return connectionRef.current;
};

export default useGameSignalR;
