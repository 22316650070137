import React, { useEffect } from "react";
import CustomTable from "../table/customTable/CustomTable";
import Pagination from "../pagination/Pagination";
import MyBetTable from "../table/myBetTable/MyBetTable";
import NoData from "../../common/nodata/NoData";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../common/constants/gameConstant";
import { gameAction } from "../../../store/slices/gameSlice";

const GameRecordList = (props) => {
  const { activeTab, dataHeader, dataColumns, gameDataList, myBetList, fetchRoundHistory, fetchBetHistory, totalPages, tableStyle, isLoading } = props;
  const dispatch = useDispatch();
  const { gameTime, recordCurrentPage } = useSelector(
    (state) => ({
      gameTime: state.game.gameTime,
      recordCurrentPage: state.game.recordCurrentPage,
    }),
    shallowEqual
  );

  const hasData =
    (activeTab === GameConstant.RecordType.RoundHistory && gameDataList.length > 0) ||
    (activeTab === GameConstant.RecordType.BetHistory && myBetList.length > 0);
  const showGameHistory = hasData && activeTab === GameConstant.RecordType.RoundHistory && gameDataList.length > 0;
  const showBetHistory = hasData && activeTab === GameConstant.RecordType.BetHistory && myBetList.length > 0;

  const updateCurrentPage = (currentPage) => dispatch(gameAction.setRecordCurrentPage(currentPage));

  //handle to reset current page value when recordtab was changed
  useEffect(() => {
    updateCurrentPage(1);
  }, [activeTab, gameTime]);

  const handlePaging = (isNext) => {
    try {
      const nextPage = isNext ? recordCurrentPage + 1 : recordCurrentPage - 1;
      if ((isNext && nextPage <= totalPages) || (!isNext && nextPage >= 1)) {
        if (activeTab === GameConstant.RecordType.BetHistory) {
          fetchBetHistory(nextPage);
        } else {
          fetchRoundHistory(nextPage);
        }
        updateCurrentPage(nextPage);
      }
    } catch (error) {}
  };

  return (
    <div>
      {!isLoading && !hasData && <NoData />}
      {showGameHistory && <CustomTable header={dataHeader} gameDataList={gameDataList} dataColumns={dataColumns} />}
      {showBetHistory && <MyBetTable myBetList={myBetList} customStyleKey={tableStyle} />}
      {hasData && <Pagination currentPage={recordCurrentPage} totalPages={totalPages} onNext={() => handlePaging(1)} onPrevious={() => handlePaging(0)} />}
    </div>
  );
};

export default GameRecordList;
