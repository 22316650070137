import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    "&.MuiTableContainer-root": {
      boxShadow: "none",
      width: "100%",
      marginTop: "9px",
    },
    "& .MuiTableCell-root:first-child": {
      minWidth: "100px",
    },
    "& .MuiTableCell-root:not(:first-child)": {
      padding: "5px 16px 5px 0px",
      textAlign: "center",
      height: "auto",
    },
  },
  head: {
    "& .MuiTableCell-root": {
      fontSize: "14px",
      color: "#B0BBCC",
      fontWeight: "400",
      borderBottom: "1px solid #151747",
      textAlign: "center",
      padding: "10px 16px",
      height: "auto",
    },
    "& .MuiTableCell-root:not(:first-child)": {
      padding: "10px 16px 10px 0px",
    },
  },
  body: {
    "& .MuiTableCell-root": {
      fontSize: "14px",
      fontWeight: "500",
      color: "#D7E8FC",
      height: "auto",
      textAlign: "center",
    },
  },
  row: {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid transparent",
    },
  },
  odd: {
    backgroundColor: "#0D1134",
  },
  even: {
    backgroundColor: "inherit",
  },
  noData: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "14px",
  },
  k3Style: {
    height: "auto",
  },
  k3ImgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  winDotRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  winDot: {
    height: 10,
    width: 10,
    borderRadius: 25,
    marginRight: 5,
  },
});
