import React from "react";
import ImageConstant from "../../common/constants/imageConstant";
import { useDispatch } from "react-redux";
import * as authEvent from "../../event/authEvent";
import GameConstant from "../../common/constants/gameConstant";
import { enqueueSnackbar } from "notistack";
import { useLoading } from "../common/provider/LoadingProvider";
import classes from "./Demo.module.css";
import { sleep } from "../../common/helpers/commonHelper";

const Demo = () => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading();

  const demoLogin = async (game) => {
    isLoading("DemoAuthLoading", true);
    try {
      const { payload: redirectUrl } = await dispatch(authEvent.demoAuth({ game, language: GameConstant.Languages.English }));
      if (!redirectUrl) enqueueSnackbar({ message: "Something went wrong. Please try again later.", options: { variant: "error" } });
      await sleep(800);
      window.location.href = redirectUrl;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading("DemoAuthLoading", false);
    }
  };

  const PosterContainer = ({ children, extraClasses, gameCode }) => {
    return (
      <div className={`overflow-hidden relative group rounded-xl cursor-pointer ${extraClasses}`} onClick={() => demoLogin(gameCode)}>
        {children}
      </div>
    );
  };

  const PosterImage = ({ imgSrc }) => {
    return <img src={imgSrc} alt="game" className="w-full h-full transform transition-transform duration-300 group-hover:scale-110" />;
  };

  return (
    <div className="sm:p-5 sm:py-8 pt-10 flex flex-col gap-2">
      <div className="flex flex-col justify-between items-center gap-3 sm:gap-8">
        <h1 className="text-center font-semibold text-lg sm:text-3xl">Welcome to Lottery!</h1>
        <h3 className="text-sm sm:text-lg">Play our games in demo mode to try before you buy!</h3>
        <div className={`${classes.border} p-3 sm:p-5`}>
          <div className="flex flex-col gap-2 sm:gap-5 items-center max-w-[700px]">
            <PosterContainer gameCode={GameConstant.GameCodes.WinGo}>
              <PosterImage imgSrc={ImageConstant.posterWinGo} />
            </PosterContainer>
            <div className="flex gap-2 sm:gap-5">
              <PosterContainer extraClasses={"flex-1"} gameCode={GameConstant.GameCodes.K5d}>
                <PosterImage imgSrc={ImageConstant.poster5d} />
              </PosterContainer>
              <PosterContainer extraClasses={"flex-1"} gameCode={GameConstant.GameCodes.K3}>
                <PosterImage imgSrc={ImageConstant.posterK3} />
              </PosterContainer>
            </div>
          </div>
        </div>
        <span>Enjoy the game!</span>
      </div>
    </div>
  );
};

export default Demo;
