import React from "react";
import { useSelector } from "react-redux";
import GameConstant from "../../common/constants/gameConstant";
import WinGoBetArea from "./winGo/betArea/WinGoBetArea";
import K3BetArea from "./k3/betArea/K3BetArea";
import K5dBetArea from "./k5d/betArea/K5dBetArea";

const BaseBetArea = () => {
  const game = useSelector((state) => state.game.game);
  return <>{game === GameConstant.Games.WinGo ? <WinGoBetArea /> : game === GameConstant.Games.K3 ? <K3BetArea /> : <K5dBetArea />}</>;
};

export default BaseBetArea;
