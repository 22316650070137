import React, { useCallback } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import ImageConstant from "../../../../common/constants/imageConstant";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment/moment";
import { useStyles } from "./MyBetTable.style";
import { formatToCurrency, toFixedWithoutRounding } from "../../../../common/helpers/commonHelper";
import { shallowEqual, useSelector } from "react-redux";
import GameConstant from "../../../../common/constants/gameConstant";
import { processBetNumWingo } from "../../../../common/constants/winGoConstant";
import BetConstant from "../../../../common/constants/betConstant";

const MyBetTable = (props) => {
  const { myBetList = [] } = props;
  const { game } = useSelector(
    (state) => ({
      game: state.game.game,
      gameType: state.game.gameType,
    }),
    shallowEqual
  );
  const classes = useStyles();

  // Mapping
  const Mapping = {
    Status: {
      [BetConstant.BetResult.Pending]: { color: "text-[#F4C623]", text: "Pending", border: "border-pending" },
      [BetConstant.BetResult.Win]: { color: "text-[#51BC16]", text: "Win", border: "border-success" },
      [BetConstant.BetResult.Lose]: { color: "text-[#F43E23]", text: "Lose", border: "border-failure" },
    },
    BetValue: {
      big: "Big",
      small: "Small",
      odd: "Odd",
      even: "Even",
    },
    K3GameType: {
      total: "Total number",
      "two-same": "2 duplicate number",
      "three-same": "3 duplicate number",
      unlike: "Different number",
    },
    K3GameMode: {
      total: "Total number",
      "any-two-same": "2 suitable numbers fit",
      "unique-two-same": "Unique pair of numbers",
      "any-three-same": "3 unique numbers",
      "unique-three-same": "3 identical numbers",
      "three-different": "3 different numbers",
      "three-consecutive": "3 consecutive numbers",
      "two-different": "2 different numbers",
    },
    K5GameType: {
      a: "A",
      b: "B",
      c: "C",
      d: "D",
      e: "E",
      total: "Sum",
    },
    WingoBetValue: {
      green: "Green",
      violet: "Violet",
      red: "Red",
      big: "Big",
      small: "Small",
    },
  };

  // Functions
  const copyToClipboard = useCallback(() => {
    enqueueSnackbar({
      message: "Copied to clipboard",
      variant: "success",
      autoHideDuration: 1500,
    });
  }, [enqueueSnackbar]);

  // Components
  const GameResult = ({ status, result }) => {
    const WinGoResult = ({ result }) => {
      return <div className={`numBet ${GameConstant.WinGo.BetList.numberBet[result]?.color} w-6 h-6 text-xs`}>{result}</div>;
    };

    const K3Result = ({ result }) => {
      result = `${result}`?.split("");
      return (
        <>
          {result?.map((r, idx) => (
            <span key={idx} className="w-6 h-6">
              <img src={ImageConstant[`diceNum${r}`]} alt={`Dice Num ${r}`} />
            </span>
          ))}
        </>
      );
    };

    const K5Result = ({ result }) => {
      result = `${result}`.split("");
      return (
        <>
          {result?.map((r, idx) => (
            <span key={idx} className="w-6 h-6 sm:w-8 sm:h-8 border-[1px] rounded-full flex justify-center items-center">
              {r}
            </span>
          ))}
        </>
      );
    };

    return (
      <>
        {status === 0 ? (
          <span className="text-gray-200 text-[10px] sm:text-sm">Pending Result</span>
        ) : game === GameConstant.Games.WinGo ? (
          <WinGoResult result={result} />
        ) : game === GameConstant.Games.K3 ? (
          <K3Result result={result} />
        ) : game === GameConstant.Games.K5d ? (
          <K5Result result={result} />
        ) : (
          <></>
        )}
      </>
    );
  };

  const GameBet = ({ bet, gameMode, gameType }) => {
    const K3Bet = () => {
      const mainContainer = "flex gap-2 flex-wrap max-w-[300px]";
      const betValuesWrapper = "px-3 py-1 rounded";
      if (gameType === GameConstant.K3.GameType.TotalNumber) {
        const betList = bet?.split(",");
        return (
          <>
            {betList?.map((b, idx) => (
              <span key={idx} className={`${betValuesWrapper} bg-gray-400 text-white flex items-center justify-center`}>
                {isNaN(b) ? Mapping.BetValue[b] : b}
              </span>
            ))}
          </>
        );
      } else if (gameType === GameConstant.K3.GameType.TwoDuplicateNumber) {
        if (gameMode === GameConstant.K3.GameMode.TwoDuplicateNumber.AnyTwoSame) {
          const betList = bet?.split(",");
          return (
            <div className={`${mainContainer}"`}>
              {betList.map((val, idx) => (
                <span key={idx} className={`${betValuesWrapper} bg-purple`}>
                  {val}
                </span>
              ))}
            </div>
          );
        } else if (gameMode === GameConstant.K3.GameMode.TwoDuplicateNumber.UniqueTwoSame) {
          const betList = bet?.split("&");
          return (
            <div className={`${mainContainer}"`}>
              {betList.map((val, idx) => (
                <span key={idx} className={`${betValuesWrapper} bg-gradient-to-r from-red to-green from-10%`}>
                  <span>{val}</span>
                </span>
              ))}
            </div>
          );
        }
      } else if (gameType === GameConstant.K3.GameType.ThreeDuplicateNumber) {
        if (gameMode === GameConstant.K3.GameMode.ThreeDuplicateNumber.UniqueThreeSame) {
          const betList = bet?.split(",");
          return (
            <div className={`${mainContainer}"`}>
              {betList.map((val, idx) => (
                <span key={idx} className={`${betValuesWrapper} bg-purple`}>
                  {val}
                </span>
              ))}
            </div>
          );
        } else if (gameMode === GameConstant.K3.GameMode.ThreeDuplicateNumber.AnyThreeSame) {
          return <div className={`${betValuesWrapper} bg-red !px-1 sm:!px-2`}>3 identical numbers</div>;
        }
      } else if (gameType === GameConstant.K3.GameType.DifferentNumber) {
        if (gameMode === GameConstant.K3.GameMode.DifferentNumber.ThreeDifferent) {
          const betList = bet?.split(",");
          return (
            <div className={`${mainContainer}"`}>
              {betList.map((val, idx) => (
                <span key={idx} className={`${betValuesWrapper} bg-purple`}>
                  {val}
                </span>
              ))}
            </div>
          );
        } else if (gameMode === GameConstant.K3.GameMode.DifferentNumber.ThreeConsecutive) {
          return <div className={`${betValuesWrapper} bg-red !px-1 sm:!px-2`}>3 consecutive numbers</div>;
        } else if (gameMode === GameConstant.K3.GameMode.DifferentNumber.TwoDifferent) {
          const betList = bet?.split(",");
          return (
            <div className={`${mainContainer}"`}>
              {betList.map((val, idx) => (
                <span key={idx} className={`${betValuesWrapper} bg-purple`}>
                  {val}
                </span>
              ))}
            </div>
          );
        }
      }
      return null;
    };

    const K5Bet = () => {
      const className = "bg-gray-400 text-white px-3 py-1 rounded";
      const isNumberBet = bet.includes(",") || !isNaN(bet);
      const bets = isNumberBet ? bet.split(",") : bet;
      return (
        <>
          {isNumberBet ? (
            bets.map((b, idx) => (
              <span key={idx} className={className}>
                {b}
              </span>
            ))
          ) : (
            <span className={className}>{Mapping.BetValue[bets]}</span>
          )}
        </>
      );
    };

    const WinGoBet = () => {
      const isNumberBet = !isNaN(bet);
      const bgColor = isNumberBet ? GameConstant.WinGo.BetList.numberBet[bet]?.color : processBetNumWingo(bet)?.[1];
      return (
        <>
          {isNumberBet ? (
            <div className={`numBet ${GameConstant.WinGo.BetList.numberBet[bet]?.color} w-6 h-6 text-xs`}>{bet}</div>
          ) : (
            <div className={`${bgColor} px-2 rounded leading-6 flex items-center justify-center min-w-[50px] sm:min-w-[65px]`}>
              {Mapping.WingoBetValue[bet]}
            </div>
          )}
        </>
      );
    };

    return (
      <>{game === GameConstant.Games.WinGo ? <WinGoBet /> : game === GameConstant.Games.K3 ? <K3Bet /> : game === GameConstant.Games.K5d ? <K5Bet /> : <></>}</>
    );
  };

  return (
    <TableContainer className={classes.root}>
      <Table>
        <TableBody className={classes.body}>
          {myBetList.map((betInfo, idx) => {
            const result = `${betInfo?.result}`;
            const selection = `${betInfo?.selection}`;
            const status = betInfo.status;
            const isPlatformProcessed = betInfo.isPlatformProcessed;
            const isBetProcessing = !isPlatformProcessed && (status === BetConstant.BetResult.Win || status === BetConstant.BetResult.Lose);
            const gameType = betInfo.gameType;
            const gameMode = betInfo.gameMode;
            const isWin = status === BetConstant.BetResult.Win;
            const amountSummary = isBetProcessing
              ? `- ${formatToCurrency(toFixedWithoutRounding(betInfo.netBetAmount, 2), betInfo.currency)}`
              : `${isWin ? "+" : "-"} ${formatToCurrency(toFixedWithoutRounding(isWin ? betInfo.payout : betInfo.netBetAmount, 2), betInfo.currency)}`;
            const statusBorder = Mapping.Status?.[status]?.border || "";

            return (
              <React.Fragment key={idx}>
                <TableRow className={`${classes.row} ${idx % 2 === 0 ? classes.odd : classes.even}`} key={`mybet-tablerow-${idx}`}>
                  <TableCell>
                    <div className="flex flex-col !text-[12px] sm:!text-[15px] gap-1">
                      {/* Show game mode / game type when apps is not wingo */}
                      {game !== GameConstant.Games.WinGo && (
                        <span className="text-white mx-auto !text-md border-2 border-white w-fit py-1 px-2 rounded-md mb-1 flex items-center justify-center min-w-[65px]">
                          {Mapping[game === GameConstant.Games.K3 ? "K3GameType" : "K5GameType"][gameType] || ""}
                          {game === GameConstant.Games.K3 && gameType !== GameConstant.K3.GameType.TotalNumber && (
                            <span className="ml-1"> - {Mapping.K3GameMode[gameMode]}</span>
                          )}
                        </span>
                      )}
                      <div className="flex flex-col justify-center gap-2 items">
                        <div className="flex justify-between items-center">
                          <span className=" text-[11px] sm:!text-[14px] text-left min-w-[65px] font-light text-[#B0BBCC]">
                            {moment(betInfo.created).utcOffset("+0530").format("YYYY-MM-DD HH:mm:ss")}
                          </span>
                          <span className="flex justify-between items-center">
                            <span className="text-[10px] sm:!text-[14px] font-light ">{betInfo.betTransactionId}</span>
                            <CopyToClipboard
                              className="!py-[3px] sm:!py-[2px] pl-1 ml-auto -mr-1"
                              value={betInfo.betTransactionId}
                              text={betInfo.betTransactionId}
                              onCopy={() => copyToClipboard(betInfo.betTransactionId)}
                            >
                              <ContentCopyIcon className="!text-[#FFFFFF] !text-[13px] sm:!text-[18px]" />
                            </CopyToClipboard>
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <span className="font-light text-left min-w-[55px]">Round ID </span>
                            <div className="flex gap-1 items-center">
                              <span className="text-center text-[10px] sm:text-sm text-gray-200">{betInfo.betGroupId}</span>
                              <CopyToClipboard
                                className="!py-[3px] sm:!py-[2px] pl-1 ml-auto -mr-1"
                                value={betInfo.betGroupId}
                                text={betInfo.betGroupId}
                                onCopy={() => copyToClipboard(betInfo.betGroupId)}
                              >
                                <ContentCopyIcon className="!text-[#FFFFFF] !text-[13px] sm:!text-[18px]" />
                              </CopyToClipboard>
                            </div>
                          </div>
                          <div className="flex flex-col font-light items-end">
                            <span>
                              Bet: <span className="font-bold">{formatToCurrency(betInfo.grossBetAmount, betInfo.currency)}</span>
                            </span>
                            <span>
                              Fee: <span className="font-bold">{formatToCurrency(betInfo.fee, betInfo.currency)}</span>
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <span className="font-light text-left  min-w-[55px]">Result </span>
                              <div className={`flex gap-1 sm:pl-1`}>
                                <GameResult status={status} result={result} />
                              </div>
                            </div>
                          </div>
                          <span className={isBetProcessing ? `text-[#F4C623]` : Mapping.Status?.[status]?.color}>{amountSummary}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col">
                            <div className="flex gap-2">
                              <span className="font-light min-w-[55px] flex items-center">Selected </span>
                              <div className="flex justify-start gap-2 flex-wrap">
                                <GameBet bet={String(selection)} gameType={gameType} gameMode={gameMode} />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-end justify-end">
                            {isBetProcessing ? (
                              <span
                                className={`text-[#F4C623] border-pending h-6 flex items-center justify-center font-extrabold border border-solid rounded px-2  min-w-[75px]`}
                              >
                                Processing
                              </span>
                            ) : (
                              <span
                                className={`
                                  ${Mapping.Status?.[status]?.color} ${statusBorder}
                                  h-6 flex items-center justify-center font-extrabold border border-solid rounded px-2  min-w-[75px]
                                `}
                              >
                                {Mapping.Status?.[status]?.text || ""}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyBetTable;
