import { getCountry } from "./localStorage";

export const silenceError = (promise) => promise.catch(() => {});

export const shuffleArray = (length) => {
  let numbersArray = [];
  for (let i = 0; i <= length - 1; i++) {
    numbersArray.push(i);
  }

  let currentIndex = numbersArray.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [numbersArray[currentIndex], numbersArray[randomIndex]] = [
      numbersArray[randomIndex],
      numbersArray[currentIndex],
    ];
  }

  return numbersArray;
};

export const debounce = (func, delay = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * Format number to currency
 *
 * @param {Number} number
 * @param {String?} currency
 *
 * @return {String} Currency formatted number with currency symbol.
 */
export const formatToCurrency = (number, currency = getCountry().currency) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
  }).format(number);
};

export const genThreeDiffPermutations = (arr) => {
  arr = [...arr];
  arr.sort();

  var items = [];

  var cap = arr.length;
  var p1 = 0;
  var p2 = 1;
  var p3 = 2;

  do {
    items.push([arr[p1], arr[p2], arr[p3]]);

    p3++;
    if (p3 !== cap) continue;

    p2++;
    p3 = p2 + 1;

    if (p2 !== cap - 1) continue;

    p1++;
    p2 = p1 + 1;
    p3 = p2 + 1;
  } while (p1 < cap - 2 && p2 < cap - 1 && p3 < cap);

  return items;
};

export const genTwoDiffPermutations = (arr) => {
  arr = [...arr];
  var items = [];

  var cap = arr.length;
  var p1 = 0;
  var p2 = 1;

  do {
    items.push([arr[p1], arr[p2]]);

    p2++;
    if (p2 !== cap) continue;

    p1++;
    p2 = p1 + 1;
  } while (p1 < cap - 1 && p2 < cap);

  return items;
};

export const toFixedWithoutRounding = (number, decimals) => {
  // Convert number to string
  let numStr = number.toString();

  // Check if the number is in scientific notation
  if (numStr.indexOf("e") !== -1) {
    let [base, exponent] = numStr.split("e").map((item) => parseInt(item, 10));
    numStr = base.toFixed(decimals) + "e" + exponent;
  }

  // Split the number into integer and fractional parts
  let [integer, fraction = ""] = numStr.split(".");

  // Truncate or pad the fraction to the desired length
  fraction = fraction.slice(0, decimals).padEnd(decimals, "0");

  // Return the formatted number
  return decimals > 0 ? `${integer}.${fraction}` : integer;
};

export const objectIsNotEmpty = (obj) => {
  if (typeof obj === "object") {
    return Reflect.ownKeys(obj).length !== 0;
  }
  return false;
};

export const deepCloneArray = (arr) => {
  return arr.map((item) => {
    if (Array.isArray(item)) {
      return deepCloneArray(item); // Recursively clone if the item is an array
    } else {
      return item; // Return the item directly if it's not an array
    }
  });
};

export function isToday(date) {
  const compareDate = new Date(date);
  const today = new Date();
  return (
    compareDate.getDate() === today.getDate() &&
    compareDate.getMonth() === today.getMonth() &&
    compareDate.getFullYear() === today.getFullYear()
  );
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const randomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);
