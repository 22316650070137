import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getSignalRId } from "../../common/helpers/localStorage";
import { useRef } from "react";

export function useSignalR() {
  let connectionRef = useRef(null);
  const reconnectDelays = [0, 2000, 5000];

  /**
   *
   * @param game {string}
   * @param eventName {string}
   * @param callback {() => void}
   * @returns {Promise<void>}
   */
  const startConnection = async (game, connectionList) => {
    try {
      const signalRId = getSignalRId();
      if (!signalRId) return;

      connectionRef.current = new HubConnectionBuilder()
        .withUrl(`${window.SIGNALR_NOTI_HUB_URL}?info=${signalRId}&game=${game}`) // Connect to the hub
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            return retryContext.previousRetryCount < reconnectDelays.length ? reconnectDelays[retryContext.previousRetryCount] : 10000;
          },
        })
        .configureLogging(LogLevel.None)
        .build();

      connectionList.forEach((e) => connectionRef.current.on(e.connectionName, e.callback));
      await connectionRef.current.start(); // Start the connection
      console.log("[Lottery] SignalR Connected!");
      return connectionRef.current;
    } catch (error) {
      // console.error('Error while establishing SignalR connection:', error);
    }
  };

  const stopConnection = async () => {
    try {
      if (connectionRef.current) {
        await connectionRef.current.stop(); // Stop the connection
        console.log("[Lottery] SignalR Disconnected!");
      }
    } catch (error) {
      // console.error('Error while stopping SignalR connection:', error);
    }
  };

  // Return the functions that can be used in the component using the hook
  return {
    startConnection,
    stopConnection,
  };
}
