import { createSlice } from "@reduxjs/toolkit";
import { gameSlice } from "./gameSlice";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    walletBalance: 0.0,
  },
  reducers: {
    setWalletBalance: (state, { payload }) => {
      state.walletBalance = payload;
    },
  },
  extraReducers: (builder) => {
    const updateWalletBalance = (state, { payload: { walletBalance } }) => {
      state.walletBalance = walletBalance;
    };
    builder.addCase(gameSlice.actions.betSuccess, updateWalletBalance);
    builder.addCase(gameSlice.actions.initUserGameInfo, updateWalletBalance);
  },
});
export const selectUser = (state) => state.user;
export const userAction = userSlice.actions;
export default userSlice.reducer;
