import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { setupInterceptors } from "./setupInterceptors";
import { useDispatch } from "react-redux";

export function InjectAxiosInterceptors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const runOnce = useRef(true);

  useEffect(() => {
    if (runOnce.current) setupInterceptors(navigate, dispatch);
    return () => {
      runOnce.current = false;
    };
  }, [navigate, dispatch]);

  // not rendering anything
  return null;
}
