import { Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const GlobalBlockProvider = ({ children }) => {
  const globalBlock = useSelector((state) => state.game.globalBlock);
  return (
    <>
      <Modal open={globalBlock.show}>
        <div className="fixed top-[50%] left-[50%] !translate-x-[-50%] !translate-y-[-50%] !z-[999999] !opacity-100 sm:w-fit w-[80%] text-center p-3 rounded-md bg-gray-400 border-2 border-white flex flex-col">
          <span>Your session has ended.</span>
          <span>Please login again.</span>
        </div>
      </Modal>
      <>{children}</>
    </>
  );
};

export default GlobalBlockProvider;
