import axios, { HttpStatusCode } from "axios";
import { enqueueSnackbar } from "notistack";

//service
import { deleteAccessToken, deleteUsername, getAccessToken, setAccessToken } from "../../common/helpers/localStorage";
import ApiConstant from "../../common/constants/apiConstant";
import { refreshAccessToken } from "../../api/authApi";
import { gameAction } from "../../store/slices/gameSlice";
import { objectIsNotEmpty } from "../../common/helpers/commonHelper";

export const setupInterceptors = (navigate, dispatch, store) => {
  const logout = () => {
    deleteAccessToken();
    deleteUsername();
    dispatch(gameAction.setGlobalBlock({ show: true, type: "unautorized" }));
    return Promise.resolve();
  };

  axios.defaults.baseURL = `${ApiConstant.HOST}${ApiConstant.APIVERSION}${ApiConstant.APIBASE}`;
  axios.defaults.timeout = 10000;
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    async (config) => {
      config.headers = { ...config.headers, Authorization: `Bearer ${getAccessToken()}` };
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === HttpStatusCode.Ok && response.data) return response.data;
      return null;
    },
    async (error) => {
      const httpStatusCode = error?.response?.status;
      let message = error?.response?.data.message;

      if (error.code === "ERR_CANCELED") return Promise.resolve({ status: 499 });

      if (httpStatusCode && httpStatusCode >= 400 && httpStatusCode < 600) {
        if (httpStatusCode === HttpStatusCode.TooManyRequests) {
          message = "You have exceeded the maximum request limit. Please try again later.";
        } else if (httpStatusCode === HttpStatusCode.ServiceUnavailable) {
          message = "Website in maintenance.";
        } else if (httpStatusCode === HttpStatusCode.Forbidden) {
          message = "Access Denied.";
          logout("Access Denied").catch((error) => enqueueSnackbar(error));
        } else if (httpStatusCode === HttpStatusCode.Unauthorized) {
          const response = await refreshAccessToken();
          const newToken = response?.accessToken;
          if (newToken) {
            setAccessToken(newToken);
            return axios.request(error.config);
          } else await logout();
        }
      }

      const errorMsg = message || error?.message || "Server Error";
      return Promise.reject(errorMsg);
    }
  );
};
