import { createSlice } from "@reduxjs/toolkit";
import GameConstant from "../../common/constants/gameConstant";
import CommonConstant from "../../common/constants/commonConstant";

export const gameSlice = createSlice({
  name: "game",
  initialState: {
    game: "",
    gameType: "",
    gameTime: 1,
    betList: null,
    gameResult: null,
    betOption: { amountOf: 1, moneyQty: 1, consent: true },
    drawer: { open: false, title: null },
    finalBetAmount: 0,
    gameBlock: false,
    gameUpdate: false, //for timeout update
    gameInfo: {
      [GameConstant.RecordType.BetHistory]: { pageConfig: {}, items: [], totalCount: 0, numberOfPages: 0 },
      [GameConstant.RecordType.RoundHistory]: { pageConfig: {}, items: [], totalCount: 0, numberOfPages: 0 },
      currentRound: {},
    },
    periodId: "",
    rollingResult: { 1: [], 3: [], 5: [], 10: [] },
    recordCurrentPage: 1,
    nextCron: { 1: {}, 3: {}, 5: {}, 10: {} },
    betResult: {
      show: false,
      isWin: true,
      winAmount: 0,
      periodId: "",
      resultSummary: {},
    },
    globalBlock: { show: false, type: "unauthorized" },
    recordType: GameConstant.RecordType.RoundHistory,
    resultModalOpen: false,
    isNewResult: false,
    loading: false,
  },
  reducers: {
    setDrawer: (state, { payload: { open, title = "" } }) => {
      state.drawer.open = open;
      state.drawer.title = title;
    },
    clearBetList: (state) => {
      state.betList = null;
    },
    setGameType: (state, { payload }) => {
      state.gameType = payload;
    },
    setGameTime: (state, { payload }) => {
      state.gameTime = payload;
    },
    setBetList: (state, { payload }) => {
      state.betList = payload;
    },
    setBetOption: (state, { payload: { field, value } }) => {
      state.betOption[field] = value;
    },
    setGameBlock: (state, { payload }) => {
      state.gameBlock = payload;
    },
    setGameResult: (state, { payload }) => {
      state.gameResult = payload;
    },
    setGameInfo: (state, { payload: { data } }) => {
      state.gameInfo[state.recordType] = { ...data };
    },
    setRecordCurrentPage: (state, { payload }) => {
      state.recordCurrentPage = payload;
    },
    setNextCron: (state, { payload }) => {
      state.nextCron = payload;
    },
    setGlobalBlock: (state, { payload }) => {
      state.globalBlock = payload;
    },
    setRecordType: (state, { payload }) => {
      state.recordType = payload;
    },
    gameRoundEnded: (state, { payload: { gameResult, periodId } }) => {
      // Update value on ui
      state.gameBlock = false;
      state.periodId = periodId;
      state.gameResult = gameResult;
      state.recordType = GameConstant.RecordType.RoundHistory;
      state.isNewResult = true;
      state.betOption = { amountOf: 1, moneyQty: 1, consent: true };
    },
    prependRoundData: (state, { payload: { processedRound } }) => {
      // Prepend round data to the list
      let roundHistory = { ...state.gameInfo[GameConstant.RecordType.RoundHistory] };
      if (roundHistory.items?.length >= 10) {
        roundHistory.items = roundHistory.items.slice(0, -1);
        roundHistory.items.unshift(processedRound);
      }
      state.gameInfo[GameConstant.RecordType.RoundHistory] = roundHistory;
    },
    prependBetData: (state, { payload: { bets } }) => {
      let betHistory = { ...state.gameInfo[GameConstant.RecordType.BetHistory] };

      let accumulatedCount = betHistory.totalCount + bets.length;
      let numberOfPages = Math.ceil(accumulatedCount / 10);

      let itemCountToRemove = accumulatedCount < CommonConstant.RecordPagination.PerPage ? 0 : (betHistory.items.length + bets.length) % 10;
      if (itemCountToRemove > 0) {
        betHistory.items = betHistory.items.slice(0, -itemCountToRemove);
      }
      betHistory.items.unshift(...bets);
      betHistory.numberOfPages = numberOfPages;
      betHistory.totalCount = accumulatedCount;
      state.gameInfo[GameConstant.RecordType.BetHistory] = betHistory;
    },
    betSuccess: (state) => {
      state.drawer.open = false;
      state.recordCurrentPage = 1;
    },
    initUserGameInfo: (state, { payload: { gameInfo } }) => {
      state.gameInfo = gameInfo;
      state.periodId = gameInfo.currentRound.period;
      state.gameResult = gameInfo.roundHistory?.items?.[0].result;
    },
    initGame: (state, { payload: game }) => {
      state.game = game;
      if (game === GameConstant.Games.K3) {
        state.gameType = GameConstant.K3.GameType.TotalNumber;
      } else if (game === GameConstant.Games.K5d) {
        state.gameType = GameConstant.K5d.GameType.A;
      } else if (game === GameConstant.Games.WinGo) {
        state.gameType = GameConstant.WinGo.GameType.WinGoBet;
      }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    showNormalResult: (state) => {
      state.resultModalOpen = true;
    },
    showWinBetResult: (state, { payload: { winAmount, periodId, resultSummary } }) => {
      state.betResult = { show: true, isWin: true, winAmount, periodId, resultSummary };
    },
    closeResult: (state) => {
      state.betResult = { show: false, isWin: true, result: null, winAmount: 0, periodId: "", resultSummary: {} };
      state.resultModalOpen = false;
      state.isNewResult = false;
    },
  },
});
export const selectGame = (state) => state.game;
export const gameAction = gameSlice.actions;
export default gameSlice.reducer;
