import BetConstant from "./betConstant";
import GameConstant from "./gameConstant";

const WinGoConstant = {
  BetColor: {
    Green: BetConstant.BetColor.Green,
    Violet: BetConstant.BetColor.Violet,
    Red: BetConstant.BetColor.Red,
    MixRed: "mixred",
    MixGreen: "mixgreen",
  },
};
export const processBetNumWingo = (bet) => {
  let res = [];
  switch (bet) {
    case BetConstant.BetSize.Big:
      res = ["Big", "blue"];
      break;
    case BetConstant.BetSize.Small:
      res = ["Small", "yellow"];
      break;
    case WinGoConstant.BetColor.Green:
      res = ["Green", "green"];
      break;
    case WinGoConstant.BetColor.Violet:
      res = ["Violet", "violet"];
      break;
    case WinGoConstant.BetColor.Red:
      res = ["Red", "red"];
      break;
    default:
      res = [bet || "", bet ? GameConstant.WinGo.BetList.numberBet[bet].color : ""];
      break;
  }
  return res;
};

export default WinGoConstant;
