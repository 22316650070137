import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import RollingDice from "../../game/k3/rollingDice/RollingDice";
import styles from "./ResultModal.module.css";
import Slot from "../../game/k5d/slot/Slot";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../common/constants/gameConstant";
import useMutePopUp from "../../hook/useMutePopUp";
import CommonConstant from "../../../common/constants/commonConstant";

const ResultModal = () => {
  const { popUpMuted } = useMutePopUp(CommonConstant.Storage.MuteResultPopUp);
  const dispatch = useDispatch();
  const { game, gameResult, resultModalOpen } = useSelector(
    (state) => ({
      game: state.game.game,
      gameResult: state.game.gameResult,
      resultModalOpen: state.game.resultModalOpen,
    }),
    shallowEqual
  );

  const [diceRolling, setDiceRolling] = useState(false);

  useEffect(() => {
    if (resultModalOpen) {
      if (game === GameConstant.Games.K3) setDiceRolling(true);
    }
  }, [resultModalOpen, game, dispatch]);

  return (
    <Modal open={resultModalOpen && !popUpMuted}>
      <div className={styles.container}>
        {game === GameConstant.Games.K3 && (
          <RollingDice rollingResult={gameResult} isRolling={diceRolling} setIsRolling={setDiceRolling} isModal={true} isNewResult={true} />
        )}
        {game === GameConstant.Games.K5d && <Slot result={gameResult} />}
        {game === GameConstant.Games.WinGo && gameResult?.[0] > -1 && (
          <div className={styles.winWrapper}>
            <div className={styles.winResult}>Result</div>
            <div className={`${styles.winNum} ${GameConstant.WinGo.BetList.numberBet[gameResult].color}`}>{gameResult}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ResultModal;
