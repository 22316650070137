import React from "react";

import { shallowEqual, useSelector } from "react-redux";
import GameConstant from "../../common/constants/gameConstant";
import RollingDice from "./k3/rollingDice/RollingDice";
import Slot from "./k5d/slot/Slot";

const BaseResultDisplay = () => {
  const { game } = useSelector(
    (state) => ({
      game: state.game.game,
    }),
    shallowEqual
  );

  return <>{game === GameConstant.Games.K3 ? <RollingDice /> : game === GameConstant.Games.K5d ? <Slot /> : null}</>;
};

export default BaseResultDisplay;
