import React from "react";
import styles from "./ThreeDuplicateNumber.module.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../../../../store/slices/gameSlice";
import CommonConstant from "../../../../../common/constants/commonConstant";
import GameConstant from "../../../../../common/constants/gameConstant";

const ThreeDuplicateNumber = () => {
  const dispatch = useDispatch();

  const { drawer, betList: betListData } = useSelector(
    (state) => ({
      drawer: state.game.drawer,
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const betList = betListData || { nums: [], checked: false };

  const btnChecked = betList.checked;
  const selectedNums = betList.nums;

  // Functions
  const updateBet = (val = null) => {
    let tempBetList = { ...betList };
    if (!drawer.open) dispatch(gameAction.setDrawer({ open: true }));

    if (val) {
      // handle nums
      let tempNums = [...tempBetList.nums];
      let idx = tempNums.indexOf(val);
      if (idx > -1) tempNums.splice(idx, 1);
      else tempNums.push(val);
      tempBetList.nums = tempNums;
    } else {
      // handle btn checked
      tempBetList.checked = !tempBetList.checked;
    }

    // Handle drawer close
    if (!tempBetList.checked && tempBetList.nums.length === 0) dispatch(gameAction.setDrawer({ open: false }));

    // Update stored bet list
    dispatch(gameAction.setBetList(tempBetList));
  };

  // Components
  const NumberRow = ({ values, itemClassName }) => {
    return (
      <div className={styles.boxNumRow}>
        {values.map((value, index) => (
          <div
            className={`${itemClassName} ${styles.numItem} ${selectedNums.includes(value) && styles.active}`}
            key={`${value}-${index}`}
            onClick={() => updateBet(value)}
          >
            {value}
          </div>
        ))}
      </div>
    );
  };

  // Renders
  return (
    <>
      <div className={styles.betWrapper}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 3 unique numbers</div>
          <div className={styles.oddsTitle}>Odds: 207.36</div>
        </div>
        <NumberRow values={CommonConstant.K3.GameSections[GameConstant.K3.GameType.ThreeDuplicateNumber]} itemClassName={styles.numbox1} />
        <div className={styles.remark}>*If the drawn number matches the bet number, you will win.</div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Choose 3 identical numbers</div>
          <div className={styles.oddsTitle}>Odds: 34.56</div>
        </div>
        <div className={styles.buttonWrapper}>
          <div className={`${styles.button} ${btnChecked && styles.active}`} onClick={() => updateBet()}>
            Choose 3 identical numbers
          </div>
        </div>
        <div className={styles.remark}>*If the drawn numbers are any three identical numbers, you will win.</div>
      </div>
    </>
  );
};

export default ThreeDuplicateNumber;
