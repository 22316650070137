import CustomSnackBar from "./CustomSnackBar";

export const snackBarConfig = {
  maxSnack: 1,
  variant: "error",
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  Components: {
    success: CustomSnackBar,
    error: CustomSnackBar,
    warning: CustomSnackBar,
    info: CustomSnackBar,
  },
};
