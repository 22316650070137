import React, { useEffect } from "react";
import CustomDrawer from "../common/customDrawer/CustomDrawer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { gameAction } from "../../store/slices/gameSlice";
import GameConstant from "../../common/constants/gameConstant";
import CommonConstant from "../../common/constants/commonConstant";
import BetSummary from "./BetSummary";
import K5dBetArea from "./k5d/betArea/K5dBetArea";
import GameMapper from "../../common/mappers/gameMapper";
import K3DrawerContent from "./k3/drawerContent/K3DrawerContent";
import * as gameEvent from "../../event/gameEvent";

const BaseDrawer = () => {
  const dispatch = useDispatch();
  const { gameType, game, drawer, gameTime, globalBlock, walletBalance } = useSelector(
    (state) => ({
      gameType: state.game.gameType,
      game: state.game.game,
      drawer: state.game.drawer,
      gameTime: state.game.gameTime,
      globalBlock: state.game.globalBlock,
      walletBalance: state.user.walletBalance,
    }),
    shallowEqual
  );

  const onClose = () => {
    dispatch(gameAction.clearBetList());
    dispatch(gameAction.setDrawer({ open: false }));
  };

  return (
    <>
      <CustomDrawer
        open={drawer.open && !globalBlock.show}
        onClose={onClose}
        title={
          game === GameConstant.Games.K3
            ? GameMapper.K3.GameTypeValue[gameType]
            : game === GameConstant.Games.WinGo
            ? drawer.title
            : CommonConstant.GameName[game]
        }
        betSummary={<BetSummary showConsent />}
        showActionBtn={false}
        timer={gameTime}
        hasBackdrop={true}
        variant="temporary"
      >
        {game === GameConstant.Games.K3 ? <K3DrawerContent /> : game === GameConstant.Games.K5d ? <K5dBetArea /> : null}
      </CustomDrawer>
    </>
  );
};

export default BaseDrawer;
