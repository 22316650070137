import { useEffect } from "react";

export const useDisableConsole = () => {
  useEffect(() => {
    if (window.APP_NODE_ENV === "PRODUCTION") {
      console.log = function () {};
      // console.error = function () {};
      console.warn = function () {};
      console.info = function () {};
    }
  }, []);
};
