import React from "react";
import { Modal } from "@mui/material";
import ImageConstant from "../../../common/constants/imageConstant";
import styles from "./CommonPopUp.module.css";

const CommonPopUp = (props) => {
  const { open, handleCloseModal, title, children } = props;

  const cancelHandler = () => {
    handleCloseModal(false);
  };
  const getTitleClassName = () => {
    switch (title) {
      case "Pre-sales rule":
        return styles.preSalesRuleText;
      default:
        return styles.gameRuleText;
    }
  };

  return (
    <Modal open={open}>
      <div className={styles.container}>
        <div className={styles.modalContainer}>
          <div className={styles.popupTitleContainer}>
            <div className={getTitleClassName()}>{title}</div>
            <img src={ImageConstant.commonPopUpTitle} alt="Popup Title" className={styles.popupTitleImg} />
          </div>
          <div className={styles.closeContainer}>
            <div onClick={cancelHandler} className={styles.closeButton}>
              <img src={ImageConstant.closeIcon} alt="Close"></img>
            </div>
          </div>
          <div className={styles.innerContainer}>
            <div className={styles.scrollableContent}>{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommonPopUp;
