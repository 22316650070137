import React, { useEffect, useRef, useState, useCallback } from "react";
import Dice from "react-dice-roll";
import ImageConstant from "../../../../common/constants/imageConstant";
import styles from "./RollingDice.module.css";
import { shallowEqual, useSelector } from "react-redux";
import GameConstant from "../../../../common/constants/gameConstant";

const RollingDice = () => {
  const [isRolling, setIsRolling] = useState(false);

  const {
    gameResult: gameResultData,
    gameInfo,
    isNewResult,
  } = useSelector(
    (state) => ({
      gameResult: state.game.gameResult,
      gameInfo: state.game.gameInfo,
      isNewResult: state.game.isNewResult,
    }),
    shallowEqual
  );

  const gameResult = gameResultData || gameInfo[GameConstant.RecordType.RoundHistory]?.items?.[0]?.result;

  const diceRef1 = useRef(null);
  const diceRef2 = useRef(null);
  const diceRef3 = useRef(null);
  const diceFaces = [
    ImageConstant.diceNum1,
    ImageConstant.diceNum2,
    ImageConstant.diceNum3,
    ImageConstant.diceNum4,
    ImageConstant.diceNum5,
    ImageConstant.diceNum6,
  ];

  const startRolling = useCallback(() => {
    return new Promise((resolve) => {
      if (diceRef1 && diceRef2 && diceRef3) {
        setIsRolling(true);
        setTimeout(() => diceRef1.current?.rollDice(), 300);
        setTimeout(() => diceRef2.current?.rollDice(), 600);
        setTimeout(() => diceRef3.current?.rollDice(), 900);
      }
      setTimeout(() => {
        resolve();
        setIsRolling(false);
      }, 2000);
    });
  }, [diceRef1, diceRef2, diceRef3, setIsRolling]);

  useEffect(() => {
    (async () => {
      if (gameResult && isNewResult) await startRolling();
    })();
  }, [gameResult]);

  return (
    <div className={styles.diceContainer}>
      <div className={styles.wrapper}>
        <img src={ImageConstant.k3Game.diceBg} className={styles.diceBg} alt="dicebg" />
        {gameResult && (
          <div className={styles.diceRow}>
            <img src={ImageConstant.k3Game.diceLeft} className={styles.arrowLeft} alt="arrowleft" />
            <img src={ImageConstant.k3Game.diceRight} className={styles.arrowRight} alt="arrowright" />
            {isRolling ? (
              <>
                <div className={styles.dice}>
                  <Dice
                    defaultValue={Number(gameResult[0])}
                    cheatValue={Number(gameResult[0])}
                    faces={diceFaces}
                    size={60}
                    rollingTime={1600}
                    ref={diceRef1}
                    triggers={[""]}
                  />
                </div>
                <div className={styles.dice}>
                  <Dice
                    defaultValue={Number(gameResult[1])}
                    cheatValue={Number(gameResult[1])}
                    faces={diceFaces}
                    size={60}
                    rollingTime={1300}
                    ref={diceRef2}
                    triggers={[""]}
                  />
                </div>
                <div className={styles.dice}>
                  <Dice
                    defaultValue={Number(gameResult[2])}
                    cheatValue={Number(gameResult[2])}
                    faces={diceFaces}
                    size={60}
                    rollingTime={1000}
                    ref={diceRef3}
                    triggers={[""]}
                  />
                </div>
              </>
            ) : (
              <>
                {gameResult.split("").map((result, index) => (
                  <div className={styles.dice} key={index}>
                    <img src={diceFaces[result - 1]} alt={`dice-${result}`} />
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RollingDice;
