import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useStyles } from "./CustomTable.style";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GameConstant from "../../../../common/constants/gameConstant";

const CustomTable = (props) => {
  const { header, gameDataList = [], dataColumns = [] } = props;
  const dispatch = useDispatch();
  const { game } = useSelector(
    (state) => ({
      game: state.game.game,
    }),
    shallowEqual
  );
  const classes = useStyles();

  const renderCellContent = (colIndex, value) => {
    if (game === GameConstant.Games.K3) {
      if (colIndex === dataColumns.length - 1) {
        return (
          <div className={classes.k3ImgContainer}>
            {Array.isArray(value) ? (
              value.map((item, idx) => (
                <div key={idx} className={classes.k3Style}>
                  {item}
                </div>
              ))
            ) : (
              <img src={value} alt="Last Img" className={classes.k3Style} />
            )}
          </div>
        );
      }
    } else if (game === GameConstant.Games.K5d) {
      if (colIndex === 1) {
        return (
          <div className="flex gap-1 justify-center">
            {`${value}`.split("").map((v, idx) => (
              <span key={idx} className="rounded-full border-[1px] w-6 h-6 p-1 text-[12px]">
                {v}
              </span>
            ))}
          </div>
        );
      } else if (colIndex === 2) {
        return <b className="text-red-500">{value}</b>;
      }
    } else if (game === GameConstant.Games.WinGo) {
      if (colIndex === 1) {
        return <div style={{ color: `${value[1]}` }}>{value[0]}</div>;
      } else if (colIndex === 3) {
        return (
          <div className={classes.winDotRow}>
            {value.map((item) => {
              return <div className={`${item} ${classes.winDot}`} key={`windot-${colIndex}-${item}`}></div>;
            })}
          </div>
        );
      }
    }

    return value;
  };

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.head}>
          <TableRow>
            {header.map((column, index) => (
              <TableCell key={`header-${index}`} style={{ minWidth: game === GameConstant.Games.K3 ? 100 : "unset" }}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {gameDataList.map((row, rowIndex) => (
            <TableRow key={`tablerow-${rowIndex}`} className={`${classes.row} ${rowIndex % 2 === 0 ? classes.odd : classes.even}`}>
              {dataColumns.map((column, colIndex) => (
                <TableCell key={`${rowIndex}-${colIndex}`}>{renderCellContent(colIndex, row[column])}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
