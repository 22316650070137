import { Button, Checkbox } from "@mui/material";
import React, { memo, useMemo, useRef, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

import { enqueueSnackbar } from "notistack";
import { formatToCurrency, genThreeDiffPermutations, genTwoDiffPermutations } from "../../common/helpers/commonHelper";
import { useLoading } from "../common/provider/LoadingProvider";
import CommonPopUp from "../common/commonPopUp/CommonPopUp";
import { useStyle } from "./BetSummary.style";
import K3Constant from "../../common/constants/k3Constant";
import PreSalesRule from "./content/PreSalesRule";
import { HttpStatusCode } from "axios";
import { shallowEqual, useDispatch, useSelector, batch } from "react-redux";
import GameConstant from "../../common/constants/gameConstant";
import { gameAction } from "../../store/slices/gameSlice";
import * as Api from "../../api/gameApi";
import CommonConstant from "../../common/constants/commonConstant";

const BetSummary = (props) => {
  // #region Variable and store declaration
  const { showConsent = false } = props;
  // const [store] = useStore();
  const { isLoading } = useLoading();
  const dispatch = useDispatch();
  const { betOption, gameType, gameTime, game, betList } = useSelector(
    (state) => ({
      betOption: state.game.betOption,
      gameType: state.game.gameType,
      gameTime: state.game.gameTime,
      game: state.game.game,
      betList: state.game.betList,
    }),
    shallowEqual
  );

  const { amountOf, moneyQty } = betOption;
  const btnRef = useRef();
  const classes = useStyle();
  // Redeclare for clearer reference of the game total calculation logic
  const amount = amountOf;
  const money = moneyQty;
  // #endregion

  // #region State declaration
  const [showPreSalesRules, setShowPreSalesRules] = useState(false);
  // #endregion

  // #region Api Hooks
  // #endregion

  // #region Store actions
  const updateBetOption = (field, value) => dispatch(gameAction.setBetOption({ field, value }));
  // #endregion

  // #region Functions
  // Functions - Restructure the data and submit to api - K3
  const submitK3 = async () => {
    let betValues = "";

    // Generate bet list in specific format
    if (gameType === GameConstant.K3.GameType.TotalNumber) {
      betValues = betList.map((val) => (isNaN(val) ? K3Constant.BetMapping[val] : val)).join(",");
    } else if (gameType === GameConstant.K3.GameType.TwoDuplicateNumber) {
      const section1Nums = betList?.[0] || [];
      const section2Nums1 = betList?.[1]?.[0] || [];
      const section2Nums2 = betList?.[1]?.[1] || [];
      betValues = `${section1Nums.join(",")}@${
        section2Nums1.length > 0 && section2Nums2.length > 0 ? section2Nums1.map((n1) => `${n1}|${section2Nums2.map((n2) => n2).join(",")}`).join("&") : ""
      }`;
    } else if (gameType === GameConstant.K3.GameType.ThreeDuplicateNumber) {
      const selectedNums = betList?.nums || [];
      const btnChecked = betList?.checked || false;
      betValues = `${selectedNums.join(",")}@${btnChecked ? K3Constant.BetCheckedStatus.Active : K3Constant.BetCheckedStatus.Inactive}`;
    } else if (gameType === GameConstant.K3.GameType.DifferentNumber) {
      const selectedNums1 = betList?.nums1 || [];
      const selectedNums2 = betList?.nums2 || [];
      const btnChecked = betList?.checked || false;
      let list1 = selectedNums1.length >= 3 ? selectedNums1.join(",") : "";
      let list2 = selectedNums2.length >= 2 ? selectedNums2.join(",") : "";
      betValues = `${list1}@${btnChecked ? K3Constant.BetCheckedStatus.Active : K3Constant.BetCheckedStatus.Inactive}@${list2}`;
    }

    handleSubmitBet(betValues);
  };

  // Functions - Restructure the data and submit to api - K5
  const submitK5 = async () => {
    const betValues = betList["checked"] || betList["nums"].join("");
    handleSubmitBet(betValues);
  };

  // Functions - Restructure the data and submit to api - Win Go
  const submitWinGo = async () => {
    const betValues = `${betList.join}`;
    handleSubmitBet(betValues);
  };

  // Functions - API Call to submit bet
  const handleSubmitBet = async (betValues) => {
    let errorCount = 0;
    if (showConsent && !betOption.consent) {
      enqueueSnackbar("Please agree the Pre-sales rules to proceed");
      errorCount++;
    }

    if (!betValues) {
      enqueueSnackbar("Please select item to bet");
      errorCount++;
    }

    if (errorCount > 0) {
      unlockSubmitBetButton();
      return;
    }

    isLoading("SubmitBet", true);
    try {
      let reqData = { betValues, betAmount: amountOf, amountMultiplier: moneyQty, gameTime: gameTime };
      let response = null;
      if (game === GameConstant.Games.K3) {
        response = await Api.submitK3Bet({ ...reqData, gameType: gameType });
      } else if (game === GameConstant.Games.K5d) {
        response = await Api.submitK5dBet({ ...reqData, gamePosition: gameType });
      } else if (game === GameConstant.Games.WinGo) {
        response = await Api.submitWinGoBet({ ...reqData });
      }

      gameSubmissionCallback(response);
      unlockSubmitBetButton();
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      isLoading("SubmitBet", false);
    }, 500);
  };

  const gameSubmissionCallback = (resp) => {
    if (resp) {
      const success = resp?.isSuccess || false;
      const message = resp?.message || "";
      const balance = resp?.balance || 0;
      const bets = resp?.bets || [];
      if (message) enqueueSnackbar(message, { variant: success ? "success" : "error", autoHideDuration: 1000 });
      if (success) {
        dispatch(gameAction.betSuccess({ walletBalance: parseFloat(balance).toFixed(2) }));
        dispatch(gameAction.prependBetData({ bets }));
        dispatch(gameAction.clearBetList());
      }
    } else {
      enqueueSnackbar("Failed to place bet", { variant: "error", autoHideDuration: 1000 });
    }
  };

  // Functions - Determine which submit function to call based on app name
  const handleSubmitGame = () => {
    btnRef.current.disabled = true;
    if (game === GameConstant.Games.K3) submitK3();
    else if (game === GameConstant.Games.K5d) submitK5();
    else if (game === GameConstant.Games.WinGo) submitWinGo();
  };

  // Cal Logic Refer: serverloto92\src\public\k3\js\main.js
  const calcGameTotalK3 = {
    [GameConstant.K3.GameType.TotalNumber]: () => {
      return Number(amount) * Number(money) * Number(betList?.length || 0);
    },
    [GameConstant.K3.GameType.TwoDuplicateNumber]: () => {
      let section1Nums = betList?.[0] || [];
      let section2Nums1 = betList?.[1]?.[0] || [];
      let section2Nums2 = betList?.[1]?.[1] || [];

      return Number(amount) * Number(money) * Number(section2Nums1.length * section2Nums2.length + section1Nums.length);
    },
    [GameConstant.K3.GameType.ThreeDuplicateNumber]: () => {
      let selectedNums = betList?.nums || [];
      let btnChecked = betList?.checked || false;

      return Number(amount) * Number(money) * Number(selectedNums.length + (btnChecked ? 1 : 0));
    },
    [GameConstant.K3.GameType.DifferentNumber]: () => {
      const numAmount = Number(amount);
      const numMoney = Number(money);

      let nums1 = betList?.nums1 || [];
      let x1 = nums1.length >= 3 ? genThreeDiffPermutations(nums1).length : 0;

      let nums2 = betList?.nums2 || [];
      let x2 = nums2.length >= 2 ? genTwoDiffPermutations(nums2).length : 0;

      let btnChecked = betList?.checked || false;
      let x3 = btnChecked ? 1 : 0;

      let subtotal = 0;
      if (x1 > 0) subtotal += x1 * numMoney;
      if (x2 > 0) subtotal += x2 * numMoney;
      if (x3 > 0) subtotal += x3 * numMoney;

      return numAmount * subtotal;
    },
  };

  // Cal Logic Refer: serverloto92\src\public\5d\js\main.js [result()]
  const calcGameTotalK5 = () => {
    let sectionOne = betList?.["checked"] || null;
    let sectionTwo = betList?.["nums"] || [];
    let betCount = sectionOne ? 1 : sectionTwo.length;

    return betCount * Number(amount) * Number(money);
  };

  const calcGameTotalWinGo = () => {
    return Number(amount) * Number(money);
  };

  // Final bet amount memo value
  const finalBetAmount = useMemo(() => {
    if (game === GameConstant.Games.K3) {
      return calcGameTotalK3?.[gameType]?.();
    } else if (game === GameConstant.Games.K5d) {
      return calcGameTotalK5();
    } else if (game === GameConstant.Games.WinGo) {
      return calcGameTotalWinGo();
    }
    return 0;
  }, [betList, amount, money, gameType]);

  //handle to toggle presales rules popup
  const togglePreSalesRules = () => {
    setShowPreSalesRules(!showPreSalesRules);
  };

  // handle unlock submit bet button
  const unlockSubmitBetButton = () => {
    if (btnRef.current) btnRef.current.disabled = false;
  };
  // #endregion

  // #region Use effects
  // #endregion

  // #region Components
  const Counter = memo(() => {
    const classes = useStyle();
    return (
      <div className={classes.counterRoot}>
        <div
          className={`${classes.counterBtn} ${betOption.moneyQty === 1 && "disabled"}`}
          onClick={() => updateBetOption("moneyQty", betOption.moneyQty > 1 ? betOption.moneyQty - 1 : 1)}
        >
          -
        </div>
        <div className={classes.counterNum}>{betOption.moneyQty}</div>
        <div className={classes.counterBtn} onClick={() => updateBetOption("moneyQty", betOption.moneyQty + 1)}>
          +
        </div>
      </div>
    );
  }, []);
  // #endregion

  return (
    <>
      <div className={classes.root}>
        <div className={classes.option}>
          <div className="label">Amount Of</div>
          <div className="opt-1 opt-item">
            {CommonConstant.BetSummaryOptions.AmountOf.map((amt, idx) => (
              <div key={idx} className={`opt-item ${betOption.amountOf === amt && "active"}`} onClick={() => updateBetOption("amountOf", amt)}>
                {amt}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.option}>
          <div className="label">Money Quantity</div>
          <Counter />
        </div>
        <div className={`${classes.option} no-label`}>
          {CommonConstant.BetSummaryOptions.Multiplier.map((mul, idx) => (
            <div key={idx} className={`opt-item ${betOption.moneyQty === mul && "active"}`} onClick={() => updateBetOption("moneyQty", mul)}>
              X{mul}
            </div>
          ))}
        </div>
        <div className={classes.option}>
          <div className="label">Total Amount</div>
          <h4>{formatToCurrency(finalBetAmount)}</h4>
        </div>
        {showConsent && (
          <div className={classes.consentRoot}>
            <Checkbox
              onChange={(e) => updateBetOption("consent", e.target.checked)}
              checkedIcon={<CheckIcon className={classes.checkIcon} />}
              checked={betOption.consent}
              className={classes.consentCheck}
            />
            <div>
              I agree to the{" "}
              <u className={classes.consentLink} onClick={togglePreSalesRules}>
                Pre-sales rules
              </u>
            </div>
          </div>
        )}
        <Button ref={btnRef} variant="contained" className={classes.confirmBtn} onClick={() => handleSubmitGame()}>
          Confirm
        </Button>
      </div>
      <CommonPopUp open={showPreSalesRules} handleCloseModal={() => togglePreSalesRules()} title={"Pre-sales rule"}>
        <PreSalesRule />
      </CommonPopUp>
    </>
  );
};

export default BetSummary;
