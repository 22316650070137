import React from "react";
import { useStyle } from "./PreSalesRule.style";

const PreSalesRule = () => {
  const classes = useStyle();
  return (
    <div className={classes.presalesContainer}>
      <div style={{ paddingBottom: "10px" }}>
        To protect the legal rights and interests of users Participate in pre-sales and maintain the normal operating sequence of pre-sales and regulations
        Rules are established according to the agreement in accordance with the relevant agreements and Rules of national laws and regulations.
      </div>
      <ol>
        <li>
          Chapter 1 Definitions
          <ol>
            <li>
              Definition of presale: mentioned sales model in which a seller bundles a product or service to collect orders consumer ordering through product
              presale and supply tools goods and services to the seller according to prior agreement.
            </li>
            <li>
              The pre-sale model is the "Sign "Consignment" refers to a fixed pre-sale amount of goods available. The "deposit" can participate in Small games
              and have a chance to win a lot profit. The deposit can be directly exchanged for goods but the Amount of money deposited cannot be exchanged.
            </li>
            <li>
              Pre-sale products: These are products provided by sellers Using the presale products tool, only presale words are highlighted on title or on the
              product detail page, other products are not used pre-sale product tools are not pre-sale products.
            </li>
            <li>Pre-sale system: Topic Access to the product tool system provided to support sales according to the sales model before.</li>
            <li>Pre-sale price of goods: is the price of goods before sale. Price of the goods Presale includes two parts: sale price and payment.</li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default PreSalesRule;
