import React, { forwardRef, useEffect, useState } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import { IoClose } from "@react-icons/all-files/io5/IoClose";

import styles from "./CustomSnackBar.module.css";
import ImageConstant from "../../../common/constants/imageConstant";

const CustomSnackBar = forwardRef((props, ref) => {
  const { id, message, title, variant, autoHideDuration, iconVariant, hideIconVariant, anchorOrigin, persist, ...other } = props;
  const { closeSnackbar } = useSnackbar();
  const [statusImg, setStatusImg] = useState(null);

  useEffect(() => {
    let statusImg,
      bgColor = "";
    if (variant) {
      switch (variant) {
        case "success":
          statusImg = ImageConstant.notiSuccess;
          bgColor = "radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #124B09 0%, #171043 100%)";
          break;
        case "error":
          statusImg = ImageConstant.notiError;
          bgColor = "radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #7B111C 0%, #171043 100%)";
          break;
        case "info":
          statusImg = ImageConstant.notiInfo;
          bgColor = "radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #002A5B 0%, #171043 100%)";
          break;
        default:
          statusImg = ImageConstant.notiWarning;
          bgColor = "radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #954B0E 0%, #171043 100%)";
          break;
      }
      setStatusImg({
        statusIcon: statusImg,
        bgColor: bgColor,
      });
    }
  }, [variant]);

  const handleCloseSnackbar = () => closeSnackbar(id);

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      {statusImg !== null && (
        <div className={styles.container} style={{ background: `${statusImg.bgColor}` }}>
          <div className={styles.icons}>
            <img src={statusImg.statusIcon} alt="status" width={40} height={40} />
          </div>
          <div className={styles.info}>
            {title !== null && <div className={styles.infoTitle}>{title}</div>}
            <div className={styles.infoContent}>{message}</div>
          </div>
          <div onClick={handleCloseSnackbar} className={styles.close}>
            <IoClose fontSize={30} />
          </div>
        </div>
      )}
    </SnackbarContent>
  );
});

export default CustomSnackBar;
