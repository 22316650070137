const IconInfo = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5490_13258)">
        <path
          d="M7 0C3.13399 0 0 3.13399 0 7C0 10.866 3.13399 14 7 14C10.866 14 14 10.866 14 7C14 3.13399 10.866 0 7 0ZM7.58333 11.0833H6.41667V9.91667H7.58333V11.0833ZM8.47599 7.2028C7.85506 7.59131 7.58333 7.79867 7.58333 8.16667V8.75H6.41667V8.16667C6.41667 7.11393 7.24951 6.59326 7.85734 6.21387C8.47827 5.82536 8.75 5.618 8.75 5.25C8.75 4.60628 8.22648 4.08333 7.58333 4.08333H6.41667C5.77352 4.08333 5.25 4.60628 5.25 5.25V5.83333H4.08333V5.25C4.08333 3.9637 5.1298 2.91667 6.41667 2.91667H7.58333C8.8702 2.91667 9.91667 3.9637 9.91667 5.25C9.91667 6.30273 9.08382 6.8234 8.47599 7.2028Z"
          fill="#2875FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5490_13258">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInfo;
